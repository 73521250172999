import React, { useEffect } from 'react';

import Analytics from '@web-solutions/module-analytics';

import  FixedButtonsInWrapper  from '@web-solutions/manage-subscription/src/manage/components/fixed-buttons-in-wrapper';

import { useRemoteConfig } from 'core/hooks/use-remote-config';

import { t } from '../../../localization';

import { goAppLink, useSliceDispatch } from '../../slice';

import { useNavigate } from '../../hooks/use-navigate-next-screen';
import { useManageContext } from '../../hooks/use-manage-context';
import { Header } from '../../components/header';

import classes from './style.module.scss';

const tKey = 'manage.benefits';
const analytics = 'manage_benefits';

export const ManageBenefitsPage: React.FC = () => {
  const dispatch = useSliceDispatch();
  const { mngSubBenefitsPage } = useRemoteConfig();
  const { fixedLink, fixedButton } = mngSubBenefitsPage;
  const { navigateNextScreen, navigatePrevSceen } = useNavigate();
  const { benefitsContent } = useManageContext();

  useEffect(() => {
    Analytics.trackEvent(analytics, 'open');
  }, []);

  const handleBackClick = () => {
    navigatePrevSceen();
  }

  const handleKeepClick = () => {
    Analytics.trackEvent(analytics, 'return_to_app');
    dispatch(goAppLink());
  };

  const handleContinueClick = () => {
    Analytics.trackEvent(analytics, 'continue');
    navigateNextScreen();
  };

  return (
    <div className={classes.wrap}>
      <Header
        titleClassName={classes.title}
        subtitleClassName={classes.subtitle}
        title={t(`${tKey}.title`)}
        subtitle={t(`${tKey}.subtitle`)}
        onBackClick={handleBackClick}
        withBackButton
      />

      {benefitsContent}

      <footer>
        <FixedButtonsInWrapper
          isFixedLink={fixedLink}
          isFixedButton={fixedButton}
          buttonTitle={t(`${tKey}.return_to_app`)}
          linkTitle={t(`${tKey}.continue`)}
          onButtonClick={handleKeepClick}
          onLinkClick={handleContinueClick}
        />
      </footer>
    </div>
  );
};
