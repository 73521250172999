import { ImagesMap } from 'core/interfaces/images';

import APPLE_PAY_1X from './apple-pay.webp';
import APPLE_PAY_2X from './apple-pay@2x.webp';
import APPLE_PAY_3X from './apple-pay@3x.webp';

import PAY_PAL_1X from './pay-pal.webp';
import PAY_PAL_2X from './pay-pal@2x.webp';
import PAY_PAL_3X from './pay-pal@3x.webp';

export const IMAGES: ImagesMap = {
  APPLE_PAY: {
    src: APPLE_PAY_1X,
    srcSet: `${APPLE_PAY_1X}, ${APPLE_PAY_2X} 2x, ${APPLE_PAY_3X} 3x`,
  },
  PAY_PAL: {
    src: PAY_PAL_1X,
    srcSet: `${PAY_PAL_1X}, ${PAY_PAL_2X} 2x, ${PAY_PAL_3X} 3x`,
  },
};