import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = process.env.REACT_APP_TITLE;

export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
export const NOTICE_EMAIL = process.env.REACT_APP_NOTICE_EMAIL;

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: process.env.REACT_APP_ADJUST_LINK,
  ADJUST_TOKEN: process.env.REACT_APP_ADJUST_TOKEN,
  WEB_APP_LINK: process.env.REACT_APP_WEB_APP_URL,
  IOS_APP_ID: process.env.REACT_APP_IOS_APP_ID,
};

export const LINES = {
  LOVE: 'love',
  HEAD: 'head',
  LIFE: 'life',
  FATE: 'fate',
};

export const LINES_COLORS = {
  [LINES.LOVE]: '#F27067',
  [LINES.HEAD]: '#F9D05D',
  [LINES.LIFE]: '#19CEBB',
  [LINES.FATE]: '#C583FA'
};

export const PATHES = {
  palm: "readings",
  chart: "birthChart",
  report: "compatibility",
  "your_day": '',
  horoscope: '',
};
