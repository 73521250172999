import UAParser from 'ua-parser-js';

export enum REDIRECT_TO_CHROME {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  ON_CLICK = 'onclick',
}

export function redirectToChrome() {
  const r = new UAParser().getResult();
  if (r?.os?.name === 'Android' && ['Facebook', 'Instagram'].includes(r?.browser?.name)) {
    window.location.href = `intent:${window.location.href}#Intent;end`;
  }
}
