import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  level?: 'h1' | 'h2' | 'h3';
  className?: string;
  children: string | React.ReactNode;
}

const Title: React.FC<Props> = ({ level = 'h3', className, children }) => {
  switch (level) {
    case 'h1':
      return (
        <h1 className={classNames([classes.title_h1, className])}>{children}</h1>
      );
    case 'h2':
      return (
        <h2 className={classNames([classes.title_h2, className])}>{children}</h2>
      );
    case 'h3':
      return (
        <h3 className={classNames([classes.title_h3, className])}>{children}</h3>
      );
    default:
      return null;
  }
};

export default Title;
