import {
  SET_LOADED,
  SET_PENDING,
  SET_APP_LINK,
} from './types';

export const initialState = {
  paymentProject: process.env.REACT_APP_PAYMENT_PROJECT,
  loaded: false,
  pending: false,
  appLink: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };

    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };
    
    case SET_APP_LINK:
      return {
        ...state,
        appLink: action.payload,
      }

    default:
      return state;
  }
};

reducer.blacklist = ['loaded', 'pending'];

export default reducer;
