import {
  getAuth,
  signInWithCustomToken,
  updateEmail,
  linkWithRedirect,
  getRedirectResult,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from '@firebase/auth';

import DeviceProps from '@magnus/react-native-device-props';

import request from 'core/utils/network';

const authDeviceId = (idfm) =>
  request.post('/auth', { device_id: idfm, });

const authFirebase = (idfm) =>
  request.post('/auth/firebase/create', { uid: idfm, })
    .then(({ custom_token }) => signInWithCustomToken(getAuth(), custom_token))
    .then((userCred) => userCred.user.getIdToken())
    .then((token) => request.post('/auth/firebase/auth', { token, }));

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm =>
      Promise.race([
        authDeviceId(idfm),
        authFirebase(idfm),
      ]));

export const signInWithGoogle = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  return linkWithRedirect(getAuth().currentUser, provider);
}

export const signInWithFacebook = () => {
  const provider = new FacebookAuthProvider();
  return linkWithRedirect(getAuth().currentUser, provider);
}

export const checkRedirectResult = () => {
  return getRedirectResult(getAuth())
    .then((result) => {
      if (result) {
        const credential = GoogleAuthProvider.credentialFromResult(result)
          || FacebookAuthProvider.credentialFromResult(result);
        if (credential) {
          return result.user;
        }
      }
    })
    .catch((error) => {
      console.warn(error);
      return error;
    });
}

export const updateUserEmail = (email) =>
  DeviceProps.getIDFM()
    .then(authFirebase)
    .then(() => updateEmail(getAuth().currentUser, email));

