import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { cacheImagesCascade } from 'core/utils/images';

import { IMAGES } from './images';

import classes from './style.module.scss';

cacheImagesCascade([IMAGES.ONE_MONTHS, IMAGES.THREE_MONTHS, IMAGES.SIX_MONTHS]);

const PREVIEWS = {
  'P1M': IMAGES.ONE_MONTHS,
  'P3M': IMAGES.THREE_MONTHS,
  'P6M': IMAGES.SIX_MONTHS,
};


const Item = ({ isActive, product, onClick, titleText, subTitleText, perWeekPrice, perText, descText}) => {
  const { period } = product;

  return (
    <div className={classes.wrap}>
      <button
        type="button"
        onClick={onClick}
        className={classNames(classes.container, { [classes.active]: isActive })}
      >
        <div className={classes.detailWrap}>
          <div className={classes.previewWrapper}>
            <img
              src={PREVIEWS[period]?.src}
              srcSet={PREVIEWS[period]?.srcSet}
              alt='product preview'
            />
          </div>
          <p className={classes.productTitle}>{titleText}</p>
          <p className={classes.productSubTitle}>{subTitleText}</p>
          <p className={classes.capsulesText}>{descText}</p>
          <div className={classes.line}></div>
          <p className={classes.perWeekTitle}>{perWeekPrice}</p>
          <p className={classes.perWeekText}>{perText}</p>
        </div>
      </button>
    </div>
  );
};

Item.propTypes = {
  isActive: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string.isRequired,
  perText: PropTypes.string.isRequired,
  perWeekPrice: PropTypes.string.isRequired,
};

export default Item;