import ONE_MONTHS_1X from './one-months.webp';
import ONE_MONTHS_2X from './one-months@2x.webp';
import ONE_MONTHS_3X from './one-months@3x.webp';

import THREE_MONTHS_1X from './three-months.webp';
import THREE_MONTHS_2X from './three-months@2x.webp';
import THREE_MONTHS_3X from './three-months@3x.webp';

import SIX_MONTHS_1X from './six-months.webp';
import SIX_MONTHS_2X from './six-months@2x.webp';
import SIX_MONTHS_3X from './six-months@3x.webp';

export const IMAGES = {
  ONE_MONTHS: {
    src: ONE_MONTHS_1X,
    srcSet: `${ONE_MONTHS_1X}, ${ONE_MONTHS_2X} 2x, ${ONE_MONTHS_3X} 3x`,
  },
  THREE_MONTHS: {
    src: THREE_MONTHS_1X,
    srcSet: `${THREE_MONTHS_1X}, ${THREE_MONTHS_2X} 2x, ${THREE_MONTHS_3X} 3x`,
  },
  SIX_MONTHS: {
    src: SIX_MONTHS_1X,
    srcSet: `${SIX_MONTHS_1X}, ${SIX_MONTHS_2X} 2x, ${SIX_MONTHS_3X} 3x`,
  },
};
