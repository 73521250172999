export enum PriceAfterTrialType {
  WEEK = 'week',
  FULL = 'full',
};

export enum ButtonPlacementType {
  FIXED = 'fixed',
  TOP = 'top',
  PLANS = 'plans',
  PAYMENT = 'payment',
};

export enum ButtonBehaviorType {
  SCROLL = 'scroll',
  MODAL = 'modal',
  NEXT = 'next',
};

export enum CardFormLayout {
  STANDART = 'standart',
  COMPACT = 'compact',
}

export enum ModeDiscountBanner {
  APPLEPAY = 'applePay',
  PAYPAL = 'paypal',
}

export enum PlansLayoutType {
  BASE = 'base',
  RADIO = 'radio',
  SLIDER = 'slider',
};
