import _throttle from 'lodash/throttle';
import React from 'react';

import { ModeGooglePay } from '../constants';

const FORM_CONTAINER_ID = 'solid-payment-form-container';

const INPUT_STYLES = {
  'border-radius': '4px',
  'border-color': 'rgba(184, 185, 195, 0.3)',
  height: '3em',
};

const LABEL_STYLES = {
  color: '#2d2d2d',
  'font-size': '0.75rem !important',
  'font-weight': '600',
};

const NOT_EMPTY_LABEL_STYLES = {
  color: '#01132c',
};

let isGooglePaymentMethodPresent = null;

class CardElement extends React.Component {
  componentDidMount() {
    this._initForm();
  }

  componentDidUpdate(prevProps) {
    if (this.props.options?.orderDetails !== prevProps.options?.orderDetails) {
      this._initForm();
    }

    if (this.props.product?.id !== prevProps.product?.id) {
      this._updateProduct();
    }

    this._updateAppleBtn();
    this._updateGoogleBtn();
  }

  _initForm = () => {
    const { options } = this.props;
    const orderDetails = options?.orderDetails;
    const labelStyles = this.props.labelStyles || LABEL_STYLES;
    const inputStyles = this.props.inputStyles || INPUT_STYLES;
    const notEmptyLabelStyles =
      this.props.notEmptyLabelStyles || NOT_EMPTY_LABEL_STYLES;

    if (orderDetails) {
      if (!this.form) {
        this.productId = this.props.product?.id;
        const data = {
          merchantData: orderDetails,
          iframeParams: {
            containerId: FORM_CONTAINER_ID,
          },
          formParams: {
            formTypeClass: 'default',
            googleFontLink:
              '//fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap',
            ...(options?.formParams || {}),
          },
          styles: {
            form_body: {
              'min-width': 'auto',
              'font-family': 'Open Sans',
            },
            body_errors: {
              display: 'none',
            },
            card_number: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            expiry_date: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            card_cvv: {
              input: {
                ...inputStyles,
                '::placeholder': {
                  'font-size': '1em',
                }
              },
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
              '.tooltip-icon': {
                display: 'none',
              },
              '.error-text': {
                display: 'none',
              },
            },
            card_holder: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            zip_code: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            email: {
              input: inputStyles,
              '.label': labelStyles,
              '.not-empty .label': notEmptyLabelStyles,
            },
            submit_button: {
              'background-color': options?.formParams?.submitButtonColor,
              'border-radius': '32px',
              'text-transform': 'none',
              'font-size': '20px',
              'font-weight': '600',
              'padding': '0em 1em',
              'height': '55px',
              '-webkit-tap-highlight-color': 'transparent',
              '.title-icon': {
                'display': 'none',
              },
              ':disabled': {
                'background-color': options?.formParams?.submitButtonColor,
                'opacity': '0.5',
              }
            }
          },
          applePayButtonParams: {
            enabled: true,
            ...(options?.applePayButtonParams || {}),
          },
          googlePayButtonParams: {
            ...(options?.googlePayButtonParams || {}),
          },
        };

        const form = (this.form = window.PaymentFormSdk.init(data));

        form.on('mounted', (e) => {
          console.log(e?.data);
          this.mounted = true;
          if (this.productId !== this.props.product?.id) {
            this._updateProduct();
          }
          if (e?.data.entity === 'form') {
            this.props.onReady && this.props.onReady(e);
          } else if (e?.data.entity === 'applebtn') {
            this._updateAppleBtn();
          } else if (e?.data.entity === 'googlebtn') {
            this._updateGoogleBtn();
            this._checkGooglePaymentMethod();
          }
        });
        form.on('submit', (e) => {
          console.log(e?.data);
          if (e?.data?.entity === 'form') {
            this.props.onSubmit && this.props.onSubmit(e);
          } else if (e?.data?.entity === 'applebtn') {
            this.props.onApplePaySubmit && this.props.onApplePaySubmit(e);
          } else if (e?.data?.entity === 'googlebtn') {
            this.props.onGooglePaySubmit && this.props.onGooglePaySubmit(e);
          }
        });
        form.on('success', (e) => {
          console.log(e?.data);
          this.props.onSuccess &&
            this.props.onSuccess({
              transaction_id: e?.data?.order?.subscription_id,
              amount: e?.data?.order?.amount / 100,
              currency: e?.data?.order?.currency,
              order: e?.data?.order,
              method: e?.data?.entity === 'form' ? 'card' : e?.data?.entity,
            });
        });
        form.on('fail', (e) => {
          console.log(e?.data);
          this.props.onError && this.props.onError(e?.data);
        });
        form.on('verify', (e) => {
          console.log(e?.data);
        });
        form.on('interaction', (e) => {
          console.log(e?.data);
          if (e.data?.target?.interaction === 'change') {
            e.valid = this.isValid = Object.keys(
              e?.data?.cardForm?.fields || {}
            ).reduce(
              (r, v) => r && !!e?.data?.cardForm?.fields[v].isValid,
              true
            );
            e.empty = !e?.data?.cardForm.isTouched;
            this.props.onChange && this.props.onChange(e);
          } else if (e.data?.target?.interaction === 'click') {
            if (e.data?.target?.name === 'applePay') {
              this.props.onApplePayClick && this.props.onApplePayClick();
            } else if (e.data?.target?.name === 'googlePay') {
              this.props.onGooglePayClick && this.props.onGooglePayClick();
            }
          }
        });
        form.on('error', (e) => {
          console.error(e?.data);
        });
        form.on('orderStatus', (e) => {
          console.log(e?.data);
          let zip;
          try {
            zip = Object.values(e?.data?.response?.transactions)?.[0]?.billing_details?.zip;
          }
          catch (ex) { }
          this.props.onUserInfoChange &&
            this.props.onUserInfoChange({
              email: e?.data?.response?.order?.customer_email,
              zip,
            });
        });
      } else {
        this._updateProduct();
      }
    } else {
      if (this.form) {
        this.form.destroy();
        delete this.form;
      }
    }
  };

  _updateProduct = _throttle(() => {
    const orderDetails = this.props.options?.orderDetails;
    const productId = this.props.product?.id;
    const intent = orderDetails?.products?.[productId] || orderDetails?.one_time_products?.[productId] || orderDetails;
    if (this.form && this.mounted && intent) {
      console.log('solidgate product update')
      this.form.update({
        partialIntent: intent.paymentIntent,
        signature: intent.signature,
      })
        .then(() => {
          this.productId = productId;
          console.log('solidgate product updated')
        });
    }
  }, 1000);

  _updateAppleBtn = () => {
    const btn = document.getElementById('apple-pay');
    if (btn) {
      const { options } = this.props;
      if (options?.applePayButtonParams?.disabled) {
        btn.style = 'height: 50px; pointer-events: none; opacity: 0.5;';
      } else {
        btn.style = 'height: 50px; pointer-events: initial; opacity: 1;';
      }
    }
  };

  _updateGoogleBtn = () => {
    const { options } = this.props;
    const btn = document.getElementById(options?.googlePayButtonParams?.containerId);
    if (btn) {
      const m = options?.googlePayButtonParams?.mode;
      if (
        m === ModeGooglePay.ENABLED ||
        (m === ModeGooglePay.ENABLED_IF_PAYMENT_METHOD_PRESENT &&
          isGooglePaymentMethodPresent)
      ) {
        if (options?.googlePayButtonParams?.disabled) {
          btn.style = 'height: 50px; pointer-events: none; opacity: 0.5;';
        } else {
          btn.style = 'height: 50px; pointer-events: initial; opacity: 1;';
        }
      } else {
        btn.style = 'height: 0; overflow: hidden; margin: 0;';
      }
    }
  };

  _checkGooglePaymentMethod = () => {
    if (isGooglePaymentMethodPresent === null) {
      try {
        const client = new window.google.payments.api.PaymentsClient({
          environment: 'PRODUCTION',
        });

        client
          .isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: [
                    'AMEX',
                    'DISCOVER',
                    'INTERAC',
                    'JCB',
                    'MASTERCARD',
                    'MIR',
                    'VISA',
                  ],
                },
              },
            ],
            existingPaymentMethodRequired: true,
          })
          .then((r) => {
            isGooglePaymentMethodPresent = true;
          })
          .catch(() => {
            isGooglePaymentMethodPresent = false;
          })
          .finally(() => {
            this._updateGoogleBtn();
          });
      } catch (ex) {
        console.warn(ex);
        isGooglePaymentMethodPresent = false;
        this._updateGoogleBtn();
      }
    }
  };

  submit() {
    this.form && this.form.submit();
  }

  render() {
    return <div id={FORM_CONTAINER_ID} className={this.props.className} />;
  }
}

export default CardElement;
