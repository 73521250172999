import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { ApplePayButton } from '@web-solutions/react-billing';

import classes from './style.module.scss';
import './style.scss';

const ApplePay = ({
  activeProduct,
  requiredShippingAddress,
  className,
  onApplePayClick,
  onApplePaySubmit,
}) => {
  const email = useSelector((state) => state.profile.email);
  const APP_NAME = t('core.appName');

  return (
    <ApplePayButton
      product={activeProduct}
      label={APP_NAME}
      requiredShippingAddress={requiredShippingAddress}
      disabled={!email}
      className={classNames(classes.btn, className)}
      onClick={onApplePayClick}
      onSubmit={onApplePaySubmit}
    />
  );
}

export default ApplePay;
