import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom'
import queryString from 'query-string';

import Analytics from '@web-solutions/module-analytics';
import { Button } from '@web-solutions/core/ui-elements';
import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { goAppLink, useSliceDispatch } from '../../slice';

import Checkbox from '../../components/checkbox';
import ReasonPopUp from '../../components/reason-popup';

import { REASONS_IDS } from '../../constants/general';

import { t } from '../../../localization';
import { useNavigate } from '../../hooks/use-navigate-next-screen';
import { Header } from '../../components/header';

import classes from './style.module.scss';

const tKey = 'manage.reason';
const category = 'manage_reason';

export const SelectReasonScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useSliceDispatch();
  const { navigateNextScreen, navigatePrevSceen } = useNavigate();
  const [isReasonPopupOpen, setIsReasonPopupOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  const { mngSubReasonPage: { changeMindOff, changeMindSelected } } = useRemoteConfig();

  useEffect(() => {
    Analytics.trackEvent(category, 'open');
  }, []);

  useEffect(() => {
    if (changeMindSelected) {
      setSelectedReason(REASONS_IDS.CHANGE_MIND);
    }
  }, [changeMindSelected]);

  const handleContinueClick = () => {
    if (selectedReason === REASONS_IDS.CHANGE_MIND) {
      Analytics.trackEvent(category, 'return_to_app');
      dispatch(goAppLink());
      return;
    }

    Analytics.trackEvent(category, 'submit', { reason: selectedReason });
    const params = queryString.parse(window.location.search);
    params.reason = selectedReason
    history.replace({
      pathname: history.location.pathname,
      search: queryString.stringify(params),
    });
    if (selectedReason === REASONS_IDS.OTHER) {
      setIsReasonPopupOpen(true);
      return;
    }

    navigateNextScreen();
  };

  const handlePopupSubmit = (reasonPopupValue: string) => {
    Analytics.trackEvent(category, 'popup_submit', { reason: REASONS_IDS.OTHER, message: reasonPopupValue });
    navigateNextScreen();
  }

  const handleReasonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReason(e.target.id);
  }

  const handleClosePopup = () => {
    Analytics.trackEvent(category, 'popup_close');
    setIsReasonPopupOpen(false)
  }

  const handleBackClick = () => {
    Analytics.trackEvent(category, 'back');
    navigatePrevSceen();
  }

  const reasons = useMemo(() => (
    Object.values(REASONS_IDS).filter((id) => !(changeMindOff && id === REASONS_IDS.CHANGE_MIND))
  ), [changeMindOff])

  return (
    <div className={classes.wrap}>
      <Header
        titleClassName={classes.title}
        title={t(`${tKey}.title`)}
        withBackButton
        onBackClick={handleBackClick}
      />
      {reasons.map((id) => (
        <Checkbox
          key={id}
          id={id}
          isChecked={id === selectedReason}
          label={t(`${tKey}.reasons.${id}`)}
          onChange={handleReasonClick}
        />
      ))}
      <div className={classes.bottom}>
        <Button
          disabled={!selectedReason}
          onClick={handleContinueClick}
          titleClassName={classes.button}
          type="button"
          className={classes.bottom_button}
          title={t(`${tKey}.button_title`)}
        />
      </div>
      <ReasonPopUp
        onClose={handleClosePopup}
        isOpen={isReasonPopupOpen}
        onSubmit={handlePopupSubmit}
      />
    </div>
  );
};
