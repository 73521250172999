/* eslint-disable no-console */
import _pick from 'lodash/pick';
import { createAction } from 'redux-actions';
import i18next from 'i18next';

import Billing from '@web-solutions/module-billing';

import Analytics from '@web-solutions/module-analytics';

import { setApiAuth } from 'core/utils/network';
import { stringifyUrlParams } from 'core/utils/url-sync';

import { auth as authRequest, updateUserEmail } from 'src/api/auth';
import { update as updateProfile, G_IDS, R_IDS, getPalmReport } from 'src/api/profile';

import { RELATIONSHIP } from 'src/constants/profile';

import { setPending } from '../app/actions';

import * as TYPES from './types';

export const setAuthorized = createAction(TYPES.SET_AUTHORIZED);
export const setLinkAuthResult = createAction(TYPES.SET_LINK_AUTH_RESULT);

const setEmail = createAction(TYPES.SET_EMAIL);
const setGender = createAction(TYPES.SET_GENDER);
const setRelStatus = createAction(TYPES.SET_REL_STATUS);
const setLang = createAction(TYPES.SET_LANG)

export const setName = createAction(TYPES.SET_NAME);
export const setHandImg = createAction(TYPES.SET_HAND_IMG);

export const setBirthDate = createAction(TYPES.SET_BIRTH_DATE);
export const setBirthTime = createAction(TYPES.SET_BIRTH_TIME);
export const setBirthPlace = createAction(TYPES.SET_BIRTH_PLACE);
export const setPartnerBirthDate = createAction(TYPES.SET_PARTNER_BIRTH_DATE);
export const setCrushZodiac = createAction(TYPES.SET_CRUSH_ZODIAC);

export const setColor = createAction(TYPES.SET_COLOR);
export const setElement = createAction(TYPES.SET_ELEMENT);
export const setFutureGoals = createAction(TYPES.SET_FUTURE_GOALS);

export const setIsUnsubscriber = createAction(TYPES.SET_IS_UNSUBSCRIBER);

export const setPalmReport = createAction(TYPES.SET_PALM_REPORT);

export const setCriticalDirection = createAction(TYPES.SET_CRITICAL_DIRECTION);
export const setDesirableGoals = createAction(TYPES.SET_DESIRABLE_GOALS);

export const auth = () => async (dispatch) =>
  authRequest()
    .then((res) => {
      const h = `Bearer ${res?.profile?.access_token}`;
      setApiAuth(h);
      Billing.setApiAuth(h);

      if (res?.profile?.name) {
        dispatch(setName(res?.profile?.name));
      }

      getPalmReport()
      .then(r => {
        if (r.palm_reading) {
          const report = {
            ...r.palm_reading,
            left_hand_dots: r.palm_reading.left_hand_dots ? JSON.parse(r.palm_reading.left_hand_dots) : null,
            right_hand_dots: r.palm_reading.right_hand_dots ? JSON.parse(r.palm_reading.right_hand_dots) : null
          }
          dispatch(setPalmReport(report));
        }
      });
        

      if (res?.profile?.birthdate && res?.profile?.birthdate !== '2000-01-01') {
        dispatch(setBirthDate(res?.profile?.birthdate));
      }

      dispatch(setGender(G_IDS[res?.profile?.gender] || null));
      dispatch(setRelStatus(R_IDS[res?.profile?.marital_status] || null));
      dispatch(setLang(i18next.language));
      dispatch(setAuthorized());
    });

export const authAndSetUserData = (isSetPending) => async (dispatch, getState) => {
  const p = getState().profile;

  if (isSetPending) {
    dispatch(setPending(true));
  }

  try {
    await updateProfile(p);
    Analytics.trackEvent('profile', 'saved', { ..._pick(p, ['email', 'name', 'relStatus', 'birthDate', 'birthTime', 'birthPlace']) });
  }
  catch (error) {
    if (error?.status !== 422) {
      throw error;
    }
    console.log('[ERROR AUTH]', error); // eslint-disable-line no-console
  }
  finally {
    if (isSetPending) {
      dispatch(setPending(false));
    }
  }
};

export const processEmail = (email, { skipFirebase } = {}) => (dispatch) => {
  Analytics.setUserProperty('email', email);
  dispatch(setEmail(email));
  stringifyUrlParams({ email })

  if (!skipFirebase) {
    Analytics.trackEvent('firebase_auth', 'update_email_submit', { email });
    updateUserEmail(email)
      .then(() => {
        Analytics.trackEvent('firebase_auth', 'update_email_success', { email });
      })
      .catch((error) => {
        Analytics.trackEvent('firebase_auth', 'update_email_error', { error: error?.message, code: error?.code, email });
      });
  }
}

export const processGender = (gender) => (dispatch) => {
  Analytics.setUserProperty('gender', gender);
  dispatch(setGender(gender));
}

export const processRelStatus = (status) => (dispatch) => {
  Analytics.setUserProperty('relStatus', status === 'broke_up' ? RELATIONSHIP.SINGLE : status);
  dispatch(setRelStatus(status));
}
