import { PaymentSystem } from '@web-solutions/react-billing';
import { ProductInfo, } from '@web-solutions/react-billing/constants';

import { ProductConfig } from '../../constants/remote-config';

import { BillingState } from '.';

export type ProductDetails = ProductConfig & ProductInfo

interface State {
  billing: BillingState,
  manage: {
    paymentSystem: PaymentSystem | null,
  },
  remoteConfig: {
    paymentSystem: PaymentSystem | null,
  },
}

export function selectPaymentSystem(state: State) {
  return state.manage?.paymentSystem || state.billing.paymentSystem || state.remoteConfig.paymentSystem;
}

export function selectProducts(state: State) {
  return state.billing.products;
}

export function selectPurchased(state: State) {
  return state.billing.purchased;
}

export function selectLoading(state: State) {
  return state.billing.loading;
}

export function selectPaddleConfig(state: State) {
  return state.billing.paddleConfig
}

export function selectPostcode(state: State) {
  return state.billing.postcode
}

export function selectOrderDetails(state: State) {
  return state.billing.orderDetails
}
