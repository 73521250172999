import { t, tm } from '@web-solutions/module-localization';

export const getButtonTitleByConfig = (config = {}, defaultTKeyText) => {
  let { uppercaseTitle, title } = config;

  if (title && typeof title === 'string' && title[0] === '_') {
    return t(title);
  }

  title = tm(title, defaultTKeyText)

  return uppercaseTitle ? title.toUpperCase() : title;
};
