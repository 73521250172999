
import Localization from '@web-solutions/module-localization';
import { initLocalization as initMS } from '@web-solutions/manage-subscription';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

const resources = {
  de: {
    translation: require('./locales/de.json'),
  },
  en: {
    translation: require('./locales/en.json'),
  },
  'es-mx': {
    translation: require('./locales/es-mx.json'),
  },
  es: {
    translation: require('./locales/es.json'),
  },
  fr: {
    translation: require('./locales/fr.json'),
  },
  'pt-br': {
    translation: require('./locales/pt-br.json'),
  },
  pt: {
    translation: require('./locales/pt.json'),
  },
};

const resourcesManSub = {
  en: require('./manage-sub/en.json'),
  de: require('./manage-sub/de.json'),
  'es-mx': require('./manage-sub/es-mx.json'),
  es: require('./manage-sub/es.json'),
  fr: require('./manage-sub/fr.json'),
  'pt-br': require('./manage-sub/pt-br.json'),
  pt: require('./manage-sub/pt.json'),
};

export function initLocalization() {
  Localization.init(resources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
  initMS(resourcesManSub);
};
