import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import { useTimer } from '../../../payment/hooks/use-timer';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

const DiscountWithTimer = ({ discount, onClick, badgeTitle, }) => {
  const { showTimerInPlanBadge } = useSelector(remoteConfigSelector);
  const timer = useTimer();
  const isShownTimer = timer?.shown && showTimerInPlanBadge;
  const discountTitle = badgeTitle || t(`${tKey}.${discount && isShownTimer ? 'discount' : 'discount_save'}`, { discount });
  const isShownTimerBlock = discount && isShownTimer;

  if (discount || badgeTitle) {
    return (
      <button type="button" className={classes.container} onClick={onClick}>
        <div className={classes.timerContainer}>
          <span className={classes.text}>{discountTitle}</span>
          {isShownTimerBlock && (
            <span className={classes.timerText}>{timer?.text}</span>
          )}
        </div>
      </button>
    );
  }

  return null;
};

DiscountWithTimer.propTypes = {
  discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
  onClick: PropTypes.func,
  badgeTitle: PropTypes.string
};

DiscountWithTimer.defaultProps = {
  discount: null,
  onClick: null,
  badgeTitle: '',
};

export default DiscountWithTimer;
