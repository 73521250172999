import { ImagesMap } from 'core/interfaces/images';

import CARD_BG_1X from './card-bg.webp';
import CARD_BG_2X from './card-bg@2x.webp';
import CARD_BG_3X from './card-bg@3x.webp';

import BILLING_BG_1X from './billing-bg.webp';
import BILLING_BG_2X from './billing-bg@2x.webp';
import BILLING_BG_3X from './billing-bg@3x.webp';

export const IMAGES: ImagesMap = {
  CARD_BG: {
    src: CARD_BG_1X,
    srcSet: `${CARD_BG_1X}, ${CARD_BG_2X} 2x, ${CARD_BG_3X} 3x`,
  },
  BILLING_BG: {
    src: BILLING_BG_1X,
    srcSet: `${BILLING_BG_1X}, ${BILLING_BG_2X} 2x, ${BILLING_BG_3X} 3x`,
  },
};