import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';

import { ROUTES } from './routes';

import { TerminateMainScreen } from './main';
import { TerminateEmailSentScreen } from './email-sent';
import { TerminateConfirmScreen } from './confirm';
import { TerminateErrorScreen } from './error';
import { TerminateSuccessScreen } from './success';

const SCREENS = {
  [ROUTES.MAIN]: TerminateMainScreen,
  [ROUTES.EMAIL_SENT]: TerminateEmailSentScreen,
  [ROUTES.CONFIRM]: TerminateConfirmScreen,
  [ROUTES.ERROR]: TerminateErrorScreen,
  [ROUTES.SUCCESS]: TerminateSuccessScreen,
};

export const Terminate = () => (
  <Suspense fallback={<div />}>
    <BrowserRouter>
      <Switch>
        {Object.values(ROUTES).map((r, i) => (
          <Route key={i} path={r} component={SCREENS[r]} exact />
        ))}
        <Redirect exact to={ROUTES.MAIN} />
      </Switch>
    </BrowserRouter>
  </Suspense>
);
