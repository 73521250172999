import React from 'react';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import 'react-phone-input-2/lib/style.css';
import classes from '../../style.module.scss';
import './index.css';

const Phone = ({ onChange, id, isValid, countryCode, value }) => (
  <PhoneInput
    value={value}
    onChange={(value) => onChange(value, id)}
    containerClass={classNames(classes.phone__container, {[classes.invalid]: !isValid})}
    inputClass={classes.phone__input}
    buttonClass={classes.phone__button}
    country={countryCode?.toLowerCase()}
  />
);

export default Phone;
