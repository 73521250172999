import { createAction } from 'redux-actions';
import UAParser from 'ua-parser-js';

import Attribution, { LinkTarget } from '@web-solutions/module-attribution';

import Analytics from '@web-solutions/module-analytics';

import { init as initRemoteConfig } from 'core/store/remote-config/actions';
import { checkActiveSubscription } from 'core/store/billing/actions';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { checkRedirectResult } from 'src/api/auth';

import { init as initRouting, } from '../routing/actions';
import { auth, setLinkAuthResult, setIsUnsubscriber } from '../profile/actions';

import { validateRemoteConfig } from './utils';

import { getMagnusToken } from './utils';
import { SET_APP_LINK, SET_LOADED, SET_PENDING } from './types';

export const setLoaded = createAction(SET_LOADED);
export const setPending = createAction(SET_PENDING);
export const setAppLink = createAction(SET_APP_LINK);

export const load = () => async (dispatch, getState) => {
  try {
    await dispatch(auth());
    await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG, {
      validate: validateRemoteConfig,
      storageKey: window.location.pathname.split('/')[1] === 'asb' ? 'ASB' : '',
    }));
    const linkAuthResult = await checkRedirectResult();
    dispatch(setLinkAuthResult(linkAuthResult));
    await dispatch(checkActiveSubscription());
    await dispatch(initRouting({ linkAuthResult }));

    const {
      remoteConfig: {
        linkTarget,
        linkTargetPromoOfferCode,
        mailingSubscriptionInitial,
      },
      profile: {
        is_unsubscriber,
      }
    } = getState();

    if (is_unsubscriber === null) {
      dispatch(setIsUnsubscriber(!mailingSubscriptionInitial));
    }

    if (linkTarget === LinkTarget.WEB || (linkTarget === LinkTarget.PROMO && linkTargetPromoOfferCode)) {
      Attribution.setRemoteConfigTarget(linkTarget, { promoOfferCode: linkTargetPromoOfferCode });
      Analytics.setUserProperty('target', linkTarget);
    }
    const appLink = await Attribution.getLinkToApp();
    dispatch(setAppLink(appLink));
  } catch (error) {
    console.warn('[ERROR INIT APP]', error); // eslint-disable-line no-console
  } finally {
    dispatch(setLoaded(true));
  }
};

export const downloadApp = () => async (_, getState) => {
  const { app: { appLink } } = getState();
  window.location.href = appLink;
};

export const redirectToChromeIos = () => async (_, getState) => {
  const r = new UAParser().getResult();
  if (r?.os?.name === 'iOS' && ['Facebook', 'Instagram'].includes(r?.browser?.name)) {
    const { app: { appLink } } = getState();
    window.open("googlechrome-x-callback://x-callback-url/open/?url=" + encodeURIComponent(appLink))
  }
}