import {
  SET_START_ROUTE,
  SET_STEP,
  SET_COMPLETED,
} from './types';

export const initialState = {
  isCompleted: false,
  currentStep: 0,
  startRoute: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_START_ROUTE:
      return {
        ...state,
        startRoute: action.payload,
      };

    case SET_COMPLETED:
      return {
        ...state,
        isCompleted: action.payload,
      };

    case SET_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    default:
      return state;
  }
};

reducer.whitelist = ['isCompleted'];

export default reducer;
