import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import { PlansContent } from '../../components/plans';

import { useNavigate } from '../../hooks/use-navigate-next-screen';

const category = 'manage_plans'
interface HistoryState {
  inFlow?: boolean,
}

export const ManagePlansPage: React.FC = () => {
  const history = useHistory();

  const { navigateNextScreen, navigatePrevSceen } = useNavigate();
  const { mngSubPlansPage } = useRemoteConfig();

  const title = mngSubPlansPage?.title
  const buttonText = mngSubPlansPage?.buttonText

  const historyState = history.location?.state as HistoryState

  useEffect(() => {
    Analytics.trackEvent(category, 'open');
  }, []);

  const handleBackClick = () => {
    Analytics.trackEvent(category, 'back');
    if (historyState?.inFlow) {
      navigatePrevSceen();
    } else {
      history.goBack();
    }
  }

  const handleClose = () => {
    navigateNextScreen()
  }

  return (
    <PlansContent
      category={category}
      title={title}
      buttonText={buttonText}
      errorPopupButton='OK'
      withCloseIcon={historyState?.inFlow}
      onBackClick={handleBackClick}
      onCloseClick={handleClose}
      withBackButton
    />
  );
};