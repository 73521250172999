import React from 'react';

import { t } from '../../../../localization';

import classes from './style.module.scss';

import { ReactComponent as Icon } from './icons/shield.svg'

const tKey = 'manage.plan';

export const PaymentSafe = () => (
  <span className={classes.root}>
    <Icon />
    {t(`${tKey}.payment_safe`)}
  </span>
);
