import { useSelector } from 'react-redux';

import { InertialApplePay, ModeApplePay, ModePayPal } from 'core/constants/billing';
import { useApplePayCanMakePaymentsWithActiveCard } from 'core/hooks/use-apple-pay-can-make-payments-with-active-card';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { useRemoteConfig } from 'core/hooks/use-remote-config';

export const useShowPayPal = () => {
  const { modePayPal } = useSelector(remoteConfigSelector);
  const applePayCanMakePaymentsWithActiveCard = useApplePayCanMakePaymentsWithActiveCard();

  return modePayPal === ModePayPal.ENABLED || (modePayPal === ModePayPal.DISABLED_IF_APPLE_PAY_WITH_CARD && applePayCanMakePaymentsWithActiveCard === false);
};

export const useShowApplePay = () => {
  const { modeApplePay } = useSelector(remoteConfigSelector);
  const applePayCanMakePaymentsWithActiveCard = useApplePayCanMakePaymentsWithActiveCard();

  return modeApplePay === ModeApplePay.ENABLED || (modeApplePay === ModeApplePay.ENABLED_IF_WITH_CARD && applePayCanMakePaymentsWithActiveCard === true);
}

export const useShowInertialApplePay = () => {
  const { inertialApplePay } = useRemoteConfig();
  const applePayCanMakePaymentsWithActiveCard = useApplePayCanMakePaymentsWithActiveCard();

  return (inertialApplePay === InertialApplePay.ENABLED || inertialApplePay === InertialApplePay.ENABLED_WITH_DEFAULT) || (inertialApplePay === InertialApplePay.ENABLED_IF_WITH_CARD && applePayCanMakePaymentsWithActiveCard);
}