import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _throttle from 'lodash/throttle';
import _forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import { ButtonBehaviorType, ButtonPlacementType } from 'core/payment/constants';
import InjectedPaymentMethodButton from 'core/summary/injected-payment-method/components/button';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';

import { Button, Portal } from 'core/ui-elements';

import { getButtonTitleByConfig } from 'core/utils/button-configurator';

import { useTimer } from '../../payment/hooks/use-timer';

import classes from './style.module.scss';

const tKey = 'core.payment_popup';

const FixedButton = ({
  activeProduct,
  buttonsConfigs,
  onlyFixed,
  onClick,
  id,
  onPayPalSubmit,
}) => {
  const [fixed, setFixed] = useState(false);
  const buttonConfigs = buttonsConfigs[ButtonPlacementType.PLANS] || {};
  const fixedButtonConfigs = buttonsConfigs[ButtonPlacementType.FIXED] || {};
  const isVisibleProducts = useRef(false);
  const { isInjectedPaymentMethodOnPage } = useSelector(remoteConfigSelector);

  const title = getButtonTitleByConfig(buttonConfigs, 'core.get_my_plan');
  const fixedBtnTitle = getButtonTitleByConfig(fixedButtonConfigs, 'core.get_my_plan');
  const isFixedButtonModalBehavior = fixedButtonConfigs.behavior === ButtonBehaviorType.MODAL;

  const timer = useTimer();

  const handleButtonClick = (e) => {
    Analytics.trackEvent('products_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.PLANS);
  };

  const handleFixedButtonClick = (e) => {
    Analytics.trackEvent('fixed_button', 'click');
    e.preventDefault();
    onClick(ButtonPlacementType.FIXED, isVisibleProducts.current);
  };

  useEffect(() => {
    const buttons = document.querySelectorAll('.sub-static-btn');
    const productsList = id && document.querySelector(`#${id}`);
    const firstProduct = productsList && productsList.querySelector('.product-item');

    const h = _throttle(() => {
      let isVisibleStaticBtn = false;

      if (firstProduct) {
        const firstProductClientRect = firstProduct.getBoundingClientRect();
        const productListRectBottom = productsList.getBoundingClientRect().bottom;

        if (
          firstProductClientRect.top - window.innerHeight + firstProductClientRect.height <= 0
          && productListRectBottom >= 0
        ) {
          isVisibleProducts.current = true;
        } else if (isVisibleProducts.current) {
          isVisibleProducts.current = false;
        }
      }

      _forEach(buttons, (button) => {
        const rect = button.getBoundingClientRect();
        const mainBtnTopPos = rect.top + rect.height;

        isVisibleStaticBtn = isVisibleStaticBtn || (window.innerHeight > rect.bottom && mainBtnTopPos > 0);
      });

      if (isVisibleStaticBtn || isVisibleProducts.current) {
        if (fixed) {
          setFixed(false);
        }
      } else if (!fixed) {
        setFixed(true);
      }
    }, 50);

    window.addEventListener('scroll', h);
    const p = setInterval(h, 200);
    h();
    return () => {
      window.removeEventListener('scroll', h);
      clearInterval(p);
    };
  }, [fixed, setFixed, isVisibleProducts, id]);

  return (
    <>
      {!onlyFixed && (
        <div className={classNames(classes.content, classes.buttonMargin)}>
          <Button
            onClick={onlyFixed ? handleFixedButtonClick : handleButtonClick}
            className={classNames('sub-static-btn', classes.button, {
              [classes.invisible]: fixed,
            })}
            titleClassName={classes.buttonTitleStyle}
            title={title}
            flare={!!buttonConfigs?.enabledFlareAnimation}
          />
          {
            isInjectedPaymentMethodOnPage && (
              <InjectedPaymentMethodButton
                activeProduct={activeProduct}
                onPayPalSubmit={onPayPalSubmit}
                onCardClick={onClick}
              />
            )
          }
        </div>
      )}
      <Portal>
        <div
          className={classNames(classes.fixed, {
            [classes.hidden]: !fixed,
          })}
        >
          {timer?.shown && !onlyFixed && (
            <div
              className={classes.discount}
              onClick={handleFixedButtonClick}
            >
              <div className={classes.timer}>
                <b>{timer?.minutes}</b><span className={classes.colon}>:</span><b>{timer?.seconds}</b>
              </div>
              {activeProduct?.discount && (
                <div>
                  <span className={classes.desc}>{t(`${tKey}.discount_fixed`, { discount: activeProduct?.discount })}</span>
                </div>
              )}
            </div>
          )}
          <div className={classes.content}>
            <Button
              onClick={handleFixedButtonClick}
              className={classNames(classes.button, 'sub-fixed-btn')}
              titleClassName={classes.buttonTitleStyle}
              title={fixedBtnTitle}
              flare={!!fixedButtonConfigs?.enabledFlareAnimation}
            />
            {
              isInjectedPaymentMethodOnPage && isFixedButtonModalBehavior && (
                <InjectedPaymentMethodButton
                  activeProduct={activeProduct}
                  onPayPalSubmit={onPayPalSubmit}
                  onCardClick={onClick}
                />
              )
            }
          </div>
        </div>
      </Portal>
    </>
  );
};

FixedButton.propTypes = {
  activeProduct: PropTypes.object.isRequired,
  buttonsConfigs: PropTypes.object.isRequired,
  onlyFixed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
  onApplePaySubmit: PropTypes.func,
  onPayPalSubmit: PropTypes.func,
};

FixedButton.defaultProps = {
  id: '',
};

export default FixedButton;
