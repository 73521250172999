import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DiscountWithTimer from '../../discount-with-timer';

import classes from './style.module.scss';

const Item = ({
  isActive,
  product,
  onClick,
  titleText,
  subTitleText,
  afterTrialText,
  perWeekPrice,
  perText,
}) => {
  const {
    textLineThrough,
    discount,
    isTrial,
    previousPerWeekTitle,
    badgeTitle,
  } = product;
  const isLightColorPerWeek = previousPerWeekTitle && !isActive;

  return (
    <div className={classes.wrap}>
      <DiscountWithTimer
        discount={discount}
        onClick={onClick}
        badgeTitle={badgeTitle}
      />
      <button
        type="button"
        onClick={onClick}
        className={classNames(classes.container, { [classes.isActiveContainer]: isActive })}
      >
        <div className={classes.detailWrap}>
          <p className={classes.productTitle}>{titleText}</p>
          <p className={classes.productSubTitle}>
            {subTitleText}
            &nbsp;
            {textLineThrough && <span className={classes.lineThrough}>{textLineThrough}</span>}
          </p>
          {isTrial && (
            <p className={classes.afterTrialText}>
              {afterTrialText}
            </p>
          )}
        </div>
        <div className={classes.perWeekWrap}>
          {previousPerWeekTitle && (
            <p className={classes.previousPerWeekTitle}>
              {previousPerWeekTitle}
            </p>
          )}
          <p className={classNames(classes.perWeekTitle, isLightColorPerWeek && classes.lightPerWeekTitle)}>
            {perWeekPrice}
          </p>
          <p className={classNames(classes.perWeekText, isLightColorPerWeek && !isActive && classes.lightPerWeekText)}>
            {perText}
          </p>
        </div>
      </button>
    </div>
  );
};

Item.propTypes = {
  isActive: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string.isRequired,
  perText: PropTypes.string.isRequired,
  perWeekPrice: PropTypes.string.isRequired,
};

export default Item;
