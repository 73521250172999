import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { terminateSlice, manageSlice } from '@web-solutions/manage-subscription';

import remoteConfig from 'core/store/remote-config';
import billing from 'core/store/billing';
import quiz from 'core/store/quiz';

import app from 'src/store/app';
import profile from 'src/store/profile';
import routing from 'src/store/routing';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const keyPrefix = `quiz-pp::`;

  const rootReducer = combineReducers({
    app: persistReducer({
      key: `app`,
      storage,
      blacklist: app.blacklist,
      whitelist: app.whitelist,
    }, app),
    remoteConfig: persistReducer({
      key: `${keyPrefix}remoteConfig`,
      storage,
      blacklist: remoteConfig.blacklist,
      whitelist: remoteConfig.whitelist,
    }, remoteConfig),
    routing: persistReducer({
      key: `${keyPrefix}routing`,
      storage,
      blacklist: routing.blacklist,
      whitelist: routing.whitelist,
    }, routing),
    billing: persistReducer({
      key: `${keyPrefix}billing`,
      storage,
      blacklist: billing.blacklist,
      whitelist: billing.whitelist,
    }, billing),
    profile: persistReducer({
      key: `${keyPrefix}profile`,
      storage,
      blacklist: profile.blacklist,
      whitelist: profile.whitelist,
    }, profile),
    quiz,
    [terminateSlice.name]: terminateSlice.reducer,
    [manageSlice.name]: manageSlice.reducer,
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
