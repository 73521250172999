const tKey = 'core.shipping_address';

export const NAMES = {
  FULL_NAME: 'fullName',
  COUNTRY: 'country',
  POSTCODE: 'postcode',
  CITY: 'city',
  ADDRESS: 'address',
  PHONE_NUMBER: 'phoneNumber',
};

export const data = [
  { id: NAMES.FULL_NAME, type: 'text', placeholder: `${tKey}.placeholder.fullName` },
  { id: NAMES.COUNTRY, type: 'text', placeholder: `${tKey}.placeholder.country` },
  { id: NAMES.POSTCODE, type: 'number', placeholder: `${tKey}.fields.postcode` },
  { id: NAMES.CITY, type: 'text', placeholder: `${tKey}.fields.city` },
  { id: NAMES.ADDRESS, type: 'text', placeholder: `${tKey}.placeholder.address` },
  { id: NAMES.PHONE_NUMBER, type: 'tel' },
];

export const initialStateValues = {
  [NAMES.FULL_NAME]: null,
  [NAMES.COUNTRY]: '',
  [NAMES.POSTCODE]: '',
  [NAMES.CITY]: '',
  [NAMES.ADDRESS]: '',
  [NAMES.PHONE_NUMBER]: '',
};

export const initialStateValidation = {
  [NAMES.FULL_NAME]: true,
  [NAMES.COUNTRY]: true,
  [NAMES.POSTCODE]: true,
  [NAMES.CITY]: true,
  [NAMES.ADDRESS]: true,
  [NAMES.PHONE_NUMBER]: true,
};

export const countries = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  GB: 'United Kingdom',
};
