import { useState, useEffect } from 'react';

declare global {
  interface Window {
    ApplePaySession: any;
  }
}

let p;

export const useApplePayCanMakePaymentsWithActiveCard = (): boolean | null => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    try {
      if (window.ApplePaySession && window.ApplePaySession.canMakePaymentsWithActiveCard) {
        (p = p || window.ApplePaySession.canMakePaymentsWithActiveCard(process.env.REACT_APP_APPLE_PAY_MERCHANT_ID || 'merchant.app.pulsebit.sub'))
          .then(function (v) {
            setValue(v);
          })
          .catch(() => {
            setValue(false);
          });
      } else {
        setValue(false);
      }
    } catch (ex) {
      setValue(false);
    }
  }, []);

  return value;
};
