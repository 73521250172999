import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { t, tm } from '@web-solutions/module-localization';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { getSubTitleText, getProductTitle } from 'core/utils/products';
import { PriceAfterTrialType, PlansLayoutType, } from 'core/payment/constants';

import BaseItem from './base-item';
import RadioItem from './radio-item';
import SliderItem from './slider-item';

const tKey = 'core.plans';
const tKeyText = 'parantaga';

const items = {
  [PlansLayoutType.BASE]: BaseItem,
  [PlansLayoutType.RADIO]: RadioItem,
  [PlansLayoutType.SLIDER]: SliderItem,
};

const Item = ({ isActive, product, onClick, }) => {
  const {
    subTitle,
    weekPrice,
    priceText,
    period,
    isTrial,
    trialPeriodDays,
    trialPeriodPrice,
    isIntroductory,
    perWeekTitle,
    perWeekText,
    descriptionText,
  } = product;

  const { plansType, priceAfterTrialType } = useSelector(remoteConfigSelector);
  const Item = items[plansType] || BaseItem;
  const priceForSubTitle = isTrial ? trialPeriodPrice : priceText;
  const titleText = getProductTitle(product);
  const subTitleText = getSubTitleText(subTitle, period, isTrial, isIntroductory, trialPeriodDays, priceForSubTitle);
  const descText = tm(descriptionText, `${tKey}.${tKeyText}.${period}`, product);
  const afterTrialText = tm(
    product.afterTrialText,
    `${tKey}.after_trial.${period}`,
    {
      ...product,
      price:
        priceAfterTrialType === PriceAfterTrialType.WEEK
          ? `${weekPrice}/${t(`core.payment_popup.price_texts.P1W`)}`
          : priceText,
      interpolation: { escapeValue: false, },
    });
  const perPeriodTKey = period.indexOf('D') === 2 ? 'per_day' : 'per_week';
  const perText = tm(perWeekText, `${tKey}.${perPeriodTKey}`);
  const perWeekPrice = perWeekTitle || weekPrice;

  const handleItemClick = (e) => {
    e.preventDefault();
    onClick(product);
  };

  return (
    <Item
      isActive={isActive}
      onClick={handleItemClick}
      product={product}
      titleText={titleText}
      subTitleText={subTitleText}
      afterTrialText={afterTrialText}
      perText={perText}
      perWeekPrice={perWeekPrice}
      descText={descText}
    />
  );
};

Item.propTypes = {
  isActive: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Item;
