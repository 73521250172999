export const SET_AUTHORIZED = 'PROFILE/SET_AUTHORIZED';
export const SET_LINK_AUTH_RESULT = 'PROFILE/SET_LINK_AUTH_RESULT';

export const SET_EMAIL = 'PROFILE/SET_EMAIL';

export const SET_NAME = 'PROFILE/SET_NAME';
export const SET_GENDER = 'PROFILE/SET_GENDER';
export const SET_REL_STATUS = 'PROFILE/SET_REL_STATUS';
export const SET_HAND_IMG = 'PROFILE/SET_HAND_IMG';
export const SET_LANG = 'PROFILE/SET_LANG';

export const SET_BIRTH_DATE = 'PROFILE/SET_BIRTH_DATE';
export const SET_BIRTH_TIME = 'PROFILE/SET_BIRTH_TIME';
export const SET_BIRTH_PLACE = 'PROFILE/SET_BIRTH_PLACE';
export const SET_PARTNER_BIRTH_DATE = 'PROFILE/SET_PARTNER_BIRTH_DATE';
export const SET_CRUSH_ZODIAC = 'PROFILE/SET_CRUSH_ZODIAC';

export const SET_COLOR = 'PROFILE/SET_COLOR';
export const SET_ELEMENT = 'PROFILE/SET_ELEMENT';
export const SET_FUTURE_GOALS = 'PROFILE/SET_FUTURE_GOALS';

export const SET_CRITICAL_DIRECTION = 'PROFILE/SET_CRITICAL_DIRECTION';
export const SET_DESIRABLE_GOALS = 'PROFILE/SET_DESIRABLE_GOALS';
export const SET_PALM_REPORT = 'PROFILE/SET_PALM_REPORT';

export const SET_IS_UNSUBSCRIBER = 'PROFILE/SET_IS_UNSUBSCRIBER';
