export const GENDER = {
  FEMALE: 'female',
  MALE: 'male',
  NON_BINARY: 'non-binary',
};

export const RELATIONSHIP = {
  SINGLE: 'single',
  MARRIED: 'married',
  SOULMATE: 'soulmate',
  ENGAGED: 'engaged',
  DIFFICULT: 'difficult',
};


export const GOALS = {
  FAMILY: "Family",
  CAREER: "Career",
  HEALTH: "Health",
  MARRIED: "Getting married",
  TRAVELING: "Traveling",
  EDUCATION: "Education",
  FRIENDS: "Friends"
};
