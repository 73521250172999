import lazy from 'react-lazy-with-preload';

import {
  QUIZ_NAMES,
  SUBSCRIPTION_NAMES,
} from 'src/constants/screens';

// SUBSCRIPTION SCREENS
const Presummary = lazy(() => import('src/screens/subscription/presummary'));
const SubMain = lazy(() => import('src/screens/subscription/main'));
const SubSuccess = lazy(() => import('src/screens/subscription/success'));
const Upsale = lazy(() => import('src/screens/subscription/upsale'));
const TrialPlanChange = lazy(() => import('src/screens/subscription/trial-plan-change'));

// QUIZ SCREENS
const WelcomePredict = lazy(() => import('src/screens/quiz/welcome-predict'));
const WelcomeRel = lazy(() => import('src/screens/quiz/welcome-rel'));
const ZodiacWheelWelcome = lazy(() => import('src/screens/quiz/welcome-zodiac-wheel'));
const Gender = lazy(() => import('src/screens/quiz/gender'));
const BirthDate = lazy(() => import('src/screens/quiz/birthdate'));
const BirthPlace = lazy(() => import('src/screens/quiz/birthplace'));
const BirthTime = lazy(() => import('src/screens/quiz/birthtime'));
const Magic = lazy(() => import('src/screens/quiz/magic'));
const ReportLoader = lazy(() => import('src/screens/quiz/report-loader'));
const QuestionMulti = lazy(() => import('src/screens/quiz/questions/multiply-answers'));
const QuestionSingle = lazy(() => import('src/screens/quiz/questions/single-answers'));
const Transition = lazy(() => import('src/screens/quiz/transition'));
const PalmReading = lazy(() => import('src/screens/quiz/palm-reading'));
const Trial = lazy(() => import('src/screens/quiz/trial'));
const QuizEmail = lazy(() => import('src/screens/quiz/email/quiz-email'));
const RelspinEmail = lazy(() => import('src/screens/quiz/email/relspin-email'));
const Mailing = lazy(() => import('src/screens/quiz/mailing'));

export const SUBSCRIPTION_SCREENS = {
  [SUBSCRIPTION_NAMES.PRESUMMARY]: Presummary,
  [SUBSCRIPTION_NAMES.MAIN]: SubMain,
  [SUBSCRIPTION_NAMES.SUCCESS]: SubSuccess,
  [SUBSCRIPTION_NAMES.UPSALE]: Upsale,
  [SUBSCRIPTION_NAMES.TRIAL_PLAN_CHANGE]: TrialPlanChange,
};

export const QUIZ_SCREENS = {
  [QUIZ_NAMES.QUIZ_WELCOME]: WelcomePredict,
  [QUIZ_NAMES.RELSPIN_WELCOME]: WelcomeRel,
  [QUIZ_NAMES.ZODIAC_WHEEL_WELCOME]: ZodiacWheelWelcome,
  [QUIZ_NAMES.COMMON_GENDER]: Gender,
  [QUIZ_NAMES.QUIZ_MAGIC]: Magic,
  [QUIZ_NAMES.QUIZ_REPORT_LOADER]: ReportLoader,
  [QUIZ_NAMES.QUIZ_BIRTHDAY]: BirthDate,
  [QUIZ_NAMES.QUIZ_BIRTHPLACE]: BirthPlace,
  [QUIZ_NAMES.QUIZ_BIRTHTIME]: BirthTime,
  [QUIZ_NAMES.QUIZ_TRANSITION_ASTRO]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_HAND]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_RELATIONSHIP_FIRST]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_RELATIONSHIP_SECOND]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_SHARING_FIRST]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_SHARING_SECOND]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_PLAN_FIRST]: Transition,
  [QUIZ_NAMES.QUIZ_TRANSITION_PLAN_SECOND]: Transition,
  [QUIZ_NAMES.QUIZ_PALM_READING]: PalmReading,
  [QUIZ_NAMES.QUIZ_TRIAL]: Trial,
  [QUIZ_NAMES.QUIZ_RELATIONSHIP_STATUS]: QuestionSingle,
  [QUIZ_NAMES.QUIZ_AFFIRMATIONS]: QuestionSingle,
  [QUIZ_NAMES.QUIZ_BEST_DATE]: QuestionMulti,
  [QUIZ_NAMES.QUIZ_LOVE_LANGUAGE]: QuestionMulti,
  [QUIZ_NAMES.QUIZ_FUTURE_GOALS]: QuestionMulti,
  [QUIZ_NAMES.QUIZ_IMAGINE_LOVE]: QuestionMulti,
  [QUIZ_NAMES.QUIZ_PERSONALITY_TRAITS]: QuestionMulti,
  [QUIZ_NAMES.QUIZ_COLORS]: QuestionSingle,
  [QUIZ_NAMES.QUIZ_ELEMENT]: QuestionSingle,
  [QUIZ_NAMES.QUIZ_EMAIL]: QuizEmail,
  [QUIZ_NAMES.RELSPIN_EMAIL]: RelspinEmail,
  [QUIZ_NAMES.QUIZ_MAILING]: Mailing,
  [QUIZ_NAMES.RELSPIN_RELATIONSHIP_STATUS]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_BEEN_TOGETHER]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_GENDER]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_IDEAL_DATE]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_HANDLE_CONFLICTS]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_SEX_LIFE]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_RELATIONSHIP_FEEL]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_RELATIONSHIP_GOAL]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_WHAT_BEST_DESCRIBES_YOU]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_PARTNER_ZODIAC_SIGN]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_ISSUES_IN_RELATIONSHIP]: QuestionMulti,
  [QUIZ_NAMES.RELSPIN_IMPORTANT_IN_RELATIONSHIP]: QuestionMulti,
  [QUIZ_NAMES.RELSPIN_LOOK_FOR_IN_PARTNER]: QuestionMulti,
  [QUIZ_NAMES.RELSPIN_IMAGINE]: QuestionMulti,
  [QUIZ_NAMES.RELSPIN_AFTER_MONTH_FOR_PAIR]: QuestionMulti,
  [QUIZ_NAMES.RELSPIN_AFTER_MONTH_FOR_SINGLE]: QuestionMulti,
  [QUIZ_NAMES.RELSPIN_PARTNER_IS_AROUND]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_TALK_WITH_PARTNER]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_KEEPING_SPARK]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_WANT_TO_FEEL]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_FULFILLING_SEX]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_FIND_IT_IMPORTANT]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_RESPECT_MY_NEED]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_HARD_TIME]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_GET_ANGRY]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_NEED_GUIDANCE]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_CANNOT_OPEN]: QuestionSingle,
  [QUIZ_NAMES.RELSPIN_BIRTHDAY]: BirthDate,
  [QUIZ_NAMES.RELSPIN_BIRTHTIME]: BirthTime,
  [QUIZ_NAMES.RELSPIN_PARTNER_BIRTHDAY]: BirthDate,
  [QUIZ_NAMES.RELSPIN_PARTNER_BIRTHTIME]: BirthTime,
  [QUIZ_NAMES.RELSPIN_BIRTHPLACE]: BirthPlace,
  [QUIZ_NAMES.RELSPIN_PARTNER_BIRTHPLACE]: BirthPlace,
};

const SCREENS = {
  ...SUBSCRIPTION_SCREENS,
  ...QUIZ_SCREENS,
};

export default SCREENS;
