import { LString } from "@web-solutions/core/constants/remote-config"
import { Currency } from '@web-solutions/react-billing/constants';

export interface RemoteConfig {
  mngSubPlansPage: {
    title: LString,
    buttonText: LString,
  },
  mngSubMainPage: {
    currentPlanPrice: string,
  },
  mngSubReasonPage: {
    enabled: boolean
    changeMindOff: boolean
    changeMindSelected: boolean
  }
  mngSubBenefitsPage: {
    enabled: boolean,
    fixedButton: boolean,
    fixedLink: boolean,
  }
  mngSubCancelSuccessPage: {
    closeEnabled: boolean
  },
  mngSubOfferFreePeriodPage: {
    enabled: boolean,
    exclusiveOfferEnabled: boolean,
    productId: string,
    subscriptionDescription: LString,
    fixedLink: boolean,
    fixedButton: boolean,
  },
  mngSubFarewellOfferPage: {
    enabled: boolean,
    notActivePrice: string
    activePrice: string,
    price: number,
    discount: string,
    currency: Currency,
    acceptBtnTitle?: LString,
    declineBtnTitle?: LString,
  },
  mngSubOfferCheapProductPage: {
    enabled: boolean,
    backEnabled: boolean,
    discountAmount: number,
    subtitle: LString,
    price: LString,
    productId: string,
  },
  mngSubTerminatePage: {
    fixedLink: boolean,
    fixedButton: boolean,
    enabled: boolean,
  },
  mngProgressPage: {
    enabled: boolean,
    fixedLink: boolean,
    fixedButton: boolean,
  }
}

export const INITIAL_CONFIG: RemoteConfig = {
  mngSubPlansPage: {
    title: '',
    buttonText: '',
  },
  mngSubMainPage: {
    currentPlanPrice: '{priceText}',
  },
  mngSubReasonPage: {
    enabled: true,
    changeMindOff: false,
    changeMindSelected: false,
  },
  mngSubBenefitsPage: {
    enabled: false,
    fixedButton: false,
    fixedLink: false,
  },
  mngSubCancelSuccessPage: {
    closeEnabled: true,
  },
  mngSubOfferFreePeriodPage: {
    enabled: false,
    exclusiveOfferEnabled: true,
    productId: '',
    subscriptionDescription: '',
    fixedLink: false,
    fixedButton: false,
  },
  mngSubFarewellOfferPage: {
    enabled: false,
    notActivePrice: '',
    activePrice: '',
    price: 1,
    discount: '',
    currency: 'USD',
  },
  mngSubOfferCheapProductPage: {
    enabled: false,
    backEnabled: true,
    discountAmount: 76,
    subtitle: '7 days free trial',
    price: 'then $4.99/ 2 weeks instead of <s>$19.99</s>',
    productId: '',
  },
  mngSubTerminatePage: {
    fixedLink: false,
    fixedButton: false,
    enabled: false
  },
  mngProgressPage: {
    enabled: false,
    fixedLink: false,
    fixedButton: false,
  }
};
