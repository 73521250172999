import _difference from 'lodash/difference';
import * as Sentry from '@sentry/browser';

import { SummarySection, RemoteConfig } from 'src/constants/remote-config';
import { QUIZ_NAMES } from 'src/constants/screens';

const ROUTES = Object.keys(QUIZ_NAMES);
const STACK = (Object.values(SummarySection) as string[]).concat(['plans']);

export function getMagnusToken() {
  return process.env.REACT_APP_MAGNUS_TOKEN!;
}

export const validateRemoteConfig = (cfg: RemoteConfig) => {
  const wrongFlowElems = _difference(cfg.flow, ROUTES);
  const errFlow = !!wrongFlowElems.length ? new Error(`wrong elements at the flow in remote config: ${wrongFlowElems}`) : null;
  if (errFlow) {
    errFlow.name = 'Remote Config Error';
    Sentry.captureException(errFlow);
  }

  const wrongLandingSatckElems = _difference(cfg.landingStack, STACK);
  const errLStack = !!wrongLandingSatckElems.length ? new Error(`wrong elements at the landingStack in remote config: ${wrongLandingSatckElems}`) : null;
  if (errLStack) {
    errLStack.name = 'Remote Config Error';
    Sentry.captureException(errLStack);
  }
}
