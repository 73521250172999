import React, { useEffect, useState } from 'react';

import Input from '../input';

const FullName = ({ onChange, id, type, value, isValid, placeholder }) => {
  const [isChangeInput, setIsChangeInput] = useState(false);
  const [fullName, setFullName] = useState('');
  const fullNameValue = !isChangeInput ? fullName : value;


  useEffect(() => {
    const handleBlur = (event) => {
      const { name } = event?.target;

      if (name === 'first_name' || name === 'last_name') {
        const firstName = document.querySelector('input[name=first_name]')?.value?.trim() || '';
        const lastName = document.querySelector('input[name=last_name]')?.value?.trim() || '';

        const v = `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`;

        setFullName(v);

        if (v && !isChangeInput) {
          onChange(v, id);
        }
      }
    };

    document.body.addEventListener('blur', handleBlur, true);

    return () => {
      document.body.removeEventListener('blur', handleBlur, true);
    };
  }, [id, isChangeInput, onChange]);

  const handleChange = (value, name) => {
    setIsChangeInput(true);
    onChange(value, name);
  };

  return (
    <Input
      id={id}
      type={type}
      value={fullNameValue}
      onChange={handleChange}
      isValid={isValid}
      placeholder={placeholder}
    />
  )
};

export default FullName;
