import React from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import classes from '../../style.module.scss';

const Input = ({ onChange, id, type, value, isValid, placeholder }) => (
  <input
    onChange={({ target}) => onChange(target.value, target.name)}
    name={id}
    type={type}
    value={value}
    className={classNames(classes.input,{[classes.invalid]: !isValid})}
    placeholder={t(placeholder)}
  />
);

export default Input;
