export const SUBSCRIPTION_NAMES = {
  PRESUMMARY: 'PRESUMMARY',
  MAIN: 'MAIN',
  SUCCESS: 'SUCCESS',
  UPSALE: 'UPSALE',
  TRIAL_PLAN_CHANGE: 'TRIAL_PLAN_CHANGE',
};

export const QUIZ_NAMES = {
  QUIZ_WELCOME: 'QUIZ_WELCOME',
  RELSPIN_WELCOME: 'RELSPIN_WELCOME',
  ZODIAC_WHEEL_WELCOME: 'ZODIAC_WHEEL_WELCOME',
  COMMON_GENDER: 'COMMON_GENDER',
  QUIZ_MAGIC: 'QUIZ_MAGIC',
  QUIZ_REPORT_LOADER: 'QUIZ_REPORT_LOADER',
  QUIZ_BIRTHDAY: 'QUIZ_BIRTHDAY',
  QUIZ_BIRTHPLACE: 'QUIZ_BIRTHPLACE',
  QUIZ_BIRTHTIME: 'QUIZ_BIRTHTIME',
  QUIZ_CARL_JUNG: 'QUIZ_CARL_JUNG',
  QUIZ_KENNY_LOGGINS: 'QUIZ_KENNY_LOGGINS',
  QUIZ_TRANSITION_ASTRO: 'QUIZ_TRANSITION_ASTRO',
  QUIZ_TRANSITION_HAND: 'QUIZ_TRANSITION_HAND',
  QUIZ_TRANSITION_RELATIONSHIP_FIRST: 'QUIZ_TRANSITION_RELATIONSHIP_FIRST',
  QUIZ_TRANSITION_RELATIONSHIP_SECOND: 'QUIZ_TRANSITION_RELATIONSHIP_SECOND',
  QUIZ_TRANSITION_SHARING_FIRST: 'QUIZ_TRANSITION_SHARING_FIRST',
  QUIZ_TRANSITION_SHARING_SECOND: 'QUIZ_TRANSITION_SHARING_SECOND',
  QUIZ_TRANSITION_PLAN_FIRST: 'QUIZ_TRANSITION_PLAN_FIRST',
  QUIZ_TRANSITION_PLAN_SECOND: 'QUIZ_TRANSITION_PLAN_SECOND',
  QUIZ_BRIAN_COX: 'QUIZ_BRIAN_COX',
  QUIZ_PALM_READING: 'QUIZ_PALM_READING',
  QUIZ_TRIAL: 'QUIZ_TRIAL',
  QUIZ_RELATIONSHIP_STATUS: 'QUIZ_RELATIONSHIP_STATUS',
  QUIZ_AFFIRMATIONS: 'QUIZ_AFFIRMATIONS',
  QUIZ_BEST_DATE: 'QUIZ_BEST_DATE',
  QUIZ_LOVE_LANGUAGE: 'QUIZ_LOVE_LANGUAGE',
  QUIZ_FUTURE_GOALS: 'QUIZ_FUTURE_GOALS',
  QUIZ_IMAGINE_LOVE: 'QUIZ_IMAGINE_LOVE',
  QUIZ_PERSONALITY_TRAITS: 'QUIZ_PERSONALITY_TRAITS',
  QUIZ_COLORS: 'QUIZ_COLORS',
  QUIZ_ELEMENT: 'QUIZ_ELEMENT',
  QUIZ_EMAIL: 'QUIZ_EMAIL',
  RELSPIN_EMAIL: 'RELSPIN_EMAIL',
  QUIZ_MAILING: 'QUIZ_MAILING',
  // spin quiz start
  // single-select
  RELSPIN_RELATIONSHIP_STATUS: 'RELSPIN_RELATIONSHIP_STATUS',
  RELSPIN_BEEN_TOGETHER: 'RELSPIN_BEEN_TOGETHER',
  RELSPIN_GENDER: 'RELSPIN_GENDER',
  RELSPIN_IDEAL_DATE: 'RELSPIN_IDEAL_DATE',
  RELSPIN_HANDLE_CONFLICTS: 'RELSPIN_HANDLE_CONFLICTS',
  RELSPIN_SEX_LIFE: 'RELSPIN_SEX_LIFE',
  RELSPIN_RELATIONSHIP_FEEL: 'RELSPIN_RELATIONSHIP_FEEL',
  RELSPIN_RELATIONSHIP_GOAL: 'RELSPIN_RELATIONSHIP_GOAL',
  RELSPIN_WHAT_BEST_DESCRIBES_YOU: 'RELSPIN_WHAT_BEST_DESCRIBES_YOU',
  RELSPIN_PARTNER_ZODIAC_SIGN: 'RELSPIN_PARTNER_ZODIAC_SIGN',
  // multi-select
  RELSPIN_ISSUES_IN_RELATIONSHIP: 'RELSPIN_ISSUES_IN_RELATIONSHIP',
  RELSPIN_IMPORTANT_IN_RELATIONSHIP: 'RELSPIN_IMPORTANT_IN_RELATIONSHIP',
  RELSPIN_LOOK_FOR_IN_PARTNER: 'RELSPIN_LOOK_FOR_IN_PARTNER',
  RELSPIN_IMAGINE: 'RELSPIN_IMAGINE',
  RELSPIN_AFTER_MONTH_FOR_PAIR: 'RELSPIN_AFTER_MONTH_FOR_PAIR',
  RELSPIN_AFTER_MONTH_FOR_SINGLE: 'RELSPIN_AFTER_MONTH_FOR_SINGLE',
  // yes-no
  RELSPIN_PARTNER_IS_AROUND: 'RELSPIN_PARTNER_IS_AROUND',
  RELSPIN_TALK_WITH_PARTNER: 'RELSPIN_TALK_WITH_PARTNER',
  RELSPIN_KEEPING_SPARK: 'RELSPIN_KEEPING_SPARK',
  RELSPIN_WANT_TO_FEEL: 'RELSPIN_WANT_TO_FEEL',
  RELSPIN_FULFILLING_SEX: 'RELSPIN_FULFILLING_SEX',
  RELSPIN_FIND_IT_IMPORTANT: 'RELSPIN_FIND_IT_IMPORTANT',
  RELSPIN_RESPECT_MY_NEED: 'RELSPIN_RESPECT_MY_NEED',
  // number
  RELSPIN_HARD_TIME: 'RELSPIN_HARD_TIME',
  RELSPIN_GET_ANGRY: 'RELSPIN_GET_ANGRY',
  RELSPIN_NEED_GUIDANCE: 'RELSPIN_NEED_GUIDANCE',
  RELSPIN_CANNOT_OPEN: 'RELSPIN_CANNOT_OPEN',
  // date/time picker
  RELSPIN_BIRTHDAY: 'RELSPIN_BIRTHDAY',
  RELSPIN_BIRTHTIME: 'RELSPIN_BIRTHTIME',
  RELSPIN_PARTNER_BIRTHDAY: 'RELSPIN_PARTNER_BIRTHDAY',
  RELSPIN_PARTNER_BIRTHTIME: 'RELSPIN_PARTNER_BIRTHTIME',
  // birth place choice
  RELSPIN_BIRTHPLACE: 'RELSPIN_BIRTHPLACE',
  RELSPIN_PARTNER_BIRTHPLACE: 'RELSPIN_PARTNER_BIRTHPLACE',
  // spin quiz end
} as const;

type Keys = keyof typeof QUIZ_NAMES;
export type QuizNamesValues = Array<typeof QUIZ_NAMES[Keys]>;
