import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';


import { QUIZ_NAMES } from 'src/constants/screens';

const ROUTES = Object.keys(QUIZ_NAMES);

const getFlow = (state) => state.remoteConfig.flow;


export const selectFlow = createSelector(
  [getFlow],
  (flow) => _intersection(flow, ROUTES) || [],
);