import React from 'react';

import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { cacheImagesCascade } from 'core/utils/images';
import { ModeDiscountBanner } from 'core/payment/constants';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'core.discount_banner';

cacheImagesCascade([IMAGES.PAY_PAL, IMAGES.APPLE_PAY]);

export const DiscountBanner = () => {
  const { discountBanner } = useRemoteConfig();

  const data = {
    [ModeDiscountBanner.PAYPAL]: {
      img: IMAGES.PAY_PAL,
      title: 'PayPal'
    },
    [ModeDiscountBanner.APPLEPAY]: {
      img: IMAGES.APPLE_PAY,
      title: 'Apple Pay'
    }
  };

  const image = data[discountBanner.paymentMethod]?.img;
  const title = data[discountBanner.paymentMethod]?.title;
  const discount = discountBanner.discount;

  return image && title && discount && discountBanner.isShowBanner ? (
    <div className={classes.banner}>
      <div className={classes.content}>
        <img
          src={image.src}
          srcSet={image.srcSet}
          alt='payment method'
        />
        <div className={classes.text}>
          <div className={classes.discount}>{t(`${tKey}.discount`, { discount: discount })}</div>
          <div className={classes.title}>{t(`${tKey}.title`, { paymentMethod: title })}</div>
        </div>
      </div>
    </div>
  ) : null;
};
