import {
  QUIZ as QUIZ_ROUTES,
  SUBSCRIPTION as SUBSCRIPTION_ROUTES,
} from 'src/constants/routes';

import { QUIZ_SCREENS, SUBSCRIPTION_SCREENS } from 'src/nav';

export const getRoutes = () => {
  let routes = { ...QUIZ_ROUTES, ...SUBSCRIPTION_ROUTES };

  return routes;
};

export const getScreens = () => {
  const screens = { ...QUIZ_SCREENS, ...SUBSCRIPTION_SCREENS };

  return screens;
};

export const preloadPage = (page) => {
  const screens = getScreens();
  const Screen = screens[page];

  if (Screen?.preload) {
    Screen.preload();
  }
};
