import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DiscountWithTimer from '../../discount-with-timer';

import classes from './style.module.scss';

const Item = ({
  isActive,
  product,
  onClick,
  titleText,
  subTitleText,
  perWeekPrice,
  perText,
}) => {
  const {
    textLineThrough,
    discount,
    badgeTitle,
    previousPerWeekTitle,
  } = product;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(classes.container, { [classes.container_active]: isActive })}
    >
      <DiscountWithTimer
        discount={discount}
        onClick={onClick}
        badgeTitle={badgeTitle}
      />
      <div className={classes.radio_outside}>
        <div className={classes.radio_inside} />
      </div>
      <div className={classes.detail}>
        <p className={classes.detail__title}>{titleText}</p>
        <div className={classes.detail__bottom}>
          <div className={classes.detail__titles}>
            {textLineThrough && <div className={classes.detail__text}>{textLineThrough}</div>}&nbsp;
            <div className={classes.detail__subtitle}>{subTitleText}</div>
          </div>
          {previousPerWeekTitle && (
            <div className={classes.detail__price}>{previousPerWeekTitle}</div>
          )}
        </div>
      </div>
      <div className={classes.week}>
        <p className={classes.week__title}>{perWeekPrice}</p>
        <p className={classes.week__text}>{perText}</p>
      </div>
    </button>
  );
};

Item.propTypes = {
  isActive: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string.isRequired,
  perText: PropTypes.string.isRequired,
  perWeekPrice: PropTypes.string.isRequired,
};

export default Item;
