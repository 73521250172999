import React from 'react';
import PropTypes from 'prop-types';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import { getPublicKeys, PaymentSystem } from '@web-solutions/react-billing';

import Analytics from '@web-solutions/module-analytics';

import PayPalButton from './paypal-button';

const getPaypalOptions = () => ({
  'client-id': getPublicKeys()?.paypal?.client_id,
  components: 'buttons',
  intent: 'subscription',
  vault: true,
  'disable-funding': 'credit,card',
});

const DEFAULT_CURRENCY = 'USD';

const customStyle = {
  shape: 'rect',
  layout: 'vertical',
  label: 'buynow',
  color: 'gold',
  tagline: 'false',
};

const PayPalProvider = ({
  product,
  requiredShippingAddress,
  isDisabled,
  onSubmit,
  className,
  style,
}) => {
  const { currency, paypalPlanId, trialPeriodDays, amountDecimal, expireAt } = product;

  if (!paypalPlanId) return null;

  const handleClick = () => {
    Analytics.trackEvent('paypal', 'click');
  };

  const handleSuccess = async (data, actions) => {
    const details = await actions?.subscription?.get();
    const address = Object.assign({}, details?.subscriber?.shipping_address?.address, details?.subscriber?.shipping_address?.name || details?.subscriber?.name);
    const { orderID, subscriptionID } = data;
    Analytics.trackEvent('paypal', 'submit');
    onSubmit({
      transaction_id: orderID,
      subscription_id: subscriptionID,
      trial_period_days: trialPeriodDays,
      amount: amountDecimal,
      currency,
      price_id: paypalPlanId,
      expire_at: expireAt,
      shipping_address: requiredShippingAddress ? address : undefined,
      method: 'paypal',
      paymentSystem: PaymentSystem.PAYPAL,
    });
  };

  const handleCancel = () => {
    Analytics.trackEvent('paypal', 'cancel');
  };

  const handleError = (err) => {
    Analytics.trackEvent('paypal', 'error', { message: String(err?.message || err), code: String(err?.code || err) });
  };

  return (
    <PayPalScriptProvider deferLoading options={getPaypalOptions()}>
      <PayPalButton
        currency={currency ? currency.toUpperCase() : DEFAULT_CURRENCY}
        planId={paypalPlanId}
        isDisabled={isDisabled}
        requiredShippingAddress={requiredShippingAddress}
        style={{ ...customStyle, ...style }}
        className={className}
        onClick={handleClick}
        onSuccess={handleSuccess}
        onCancel={handleCancel}
        onError={handleError}
      />
    </PayPalScriptProvider>
  );
};

PayPalProvider.propTypes = {
  product: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  style: PropTypes.object
};

PayPalProvider.defaultProps = {
  isDisabled: false,
  style: {},
};

export default PayPalProvider;
