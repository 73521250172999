import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { PlansLayoutType } from 'core/payment/constants';

import classes from '../../style.module.scss';

interface Props {
  textLineThrough: string;
  previousPerWeekTitle: string;
  trialPeriodPrice: string;
  className?: string;
}
const TrialPrice: React.FC<Props> = ({
  textLineThrough,
  previousPerWeekTitle,
  trialPeriodPrice,
  className,
}) => {
  const { plansType } = useSelector(remoteConfigSelector);
  const isBasePlansType = plansType === PlansLayoutType.BASE;

  return (
    <p className={classNames(classes.aboutItemText, className)}>
      <span className={classes.lineThrough}>{isBasePlansType ? textLineThrough : previousPerWeekTitle}</span>
      <span> {trialPeriodPrice}</span>
    </p>
  )
};

export default TrialPrice;
