import ReactBilling from '@web-solutions/react-billing';

import API from './api';

import { getExtraParams } from './utils';

const Billing = {
  paymentSystemProject: '',
  paymentSystemType: '',

  setApiAuth(apiAuth) {
    this.apiAuth = apiAuth;
  },

  getPaymentParams({ paymentSystem } = {}) {
    return {
      payment_system_type: paymentSystem || this.paymentSystemType,
      payment_system_project: this.paymentSystemProject,
    };
  },

  getHeaders() {
    return {
      Authorization: this.apiAuth,
    };
  },

  async init(paymentSystem, paymentProject) {
    this.paymentSystemType = paymentSystem;
    this.paymentSystemProject = paymentProject;

    const headers = this.getHeaders();

    const keys = await API.getPublicKey(this.getPaymentParams(), headers);

    return ReactBilling.init(paymentSystem, keys);
  },

  async getPrices(pricesIds, { paymentSystem } = {}) {
    const headers = this.getHeaders();
    const params = {
      search: `external_id:${pricesIds.join(',')}`,
      searchFields: 'external_id:in',
      ...this.getPaymentParams({ paymentSystem }),
    };
    return (await API.getPrices(params, headers)).data;
  },

  async createOrder({ one_time_product, one_time_products, productId, orderDescription, products, paymentSystem }) {
    const extra = await getExtraParams();

    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      one_time_product,
      one_time_products: one_time_products || undefined,
      product_id: productId,
      order_description: orderDescription,
      products,
    };

    return API.createOrder(body, this.getHeaders());
  },

  async createCheckout({ productId, checkoutId, paymentMethod, successUrl, paymentSystem }) {
    const extra = await getExtraParams();
    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...extra,
      product_id: productId,
      checkout_id: checkoutId,
      payment_method: paymentMethod,
      success_url: successUrl,
    };

    return API.createCheckout(body, this.getHeaders());
  },

  async createCustomer({ email, paymentSystem }) {
    const headers = this.getHeaders();
    const body = {
      ...this.getPaymentParams({ paymentSystem }),
      ...(email && { email }),
    };

    return (await API.createCustomer(body, headers)).data;
  },

  async subscribe({ paymentSystem, ...formData }) {
    const headers = this.getHeaders();
    const extra = await getExtraParams();

    const body = {
      ...extra,
      ...formData,
      ...this.getPaymentParams({ paymentSystem }),
    };

    return (await API.createSubscription(body, headers)).data;
  },

  async getSubscriptions() {
    const headers = this.getHeaders();
    return API.getSubscriptions(headers);
  },

  async requestCancel(email) {
    const body = {
      email,
    };

    return API.cancelSubscription(body);
  },

  async submitCancelSubscription(params) {
    return API.submitCancelSubscription(params);
  },

  async createOneClickPayment({ amount, currency, productCode, description, successUrl, failUrl }) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      amount,
      description,
      currency,
      success_url: successUrl,
      fail_url: failUrl,
      product_code: productCode,
    };

    return API.createOneClickPayment(body, headers);
  },

  async getOneTimePurchases() {
    const headers = this.getHeaders();
    return API.getOneTimePurchases(headers);
  },

  async changePlan({ subscriptionId, productId, isNewSubscription, successUrl, failUrl }) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      subscriptionId,
      planCode: productId,
      isNewSubscription: !!isNewSubscription,
      success_url: successUrl,
      fail_url: failUrl,
    };

    return API.changePlan(body, headers);
  },

  async giveProduct(productId) {
    const headers = this.getHeaders();

    const body = {
      ...this.getPaymentParams(),
      product_code: productId,
    };

    return API.giveProduct(body, headers);
  }
};

export default Billing;
