import React, { useState, useCallback, useMemo, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Provider, PaymentSystem } from '@web-solutions/react-billing';
import Analytics from '@web-solutions/module-analytics';

import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { ProductDetails, selectLoading, selectPaddleConfig, selectPaymentSystem, selectPostcode, selectPurchased } from 'core/store/billing/selectors';
import { setInjectedPaymentMethod, switchToReservePaymentSystem } from 'core/store/billing/actions';
import { Preloader } from 'core/ui-elements';

import { ModeGooglePay } from 'core/constants/billing';
import {
  initOrders,
  createCustomer,
  handleErrorPurchase,
  handleSuccessPurchase,
  setIsSubmitByCard,
  subscribe,
  setPostCode,
} from 'core/store/billing/actions';

import BasePaymentModal from 'core/payment/base';
import ThreeDSecure from 'core/payment/components/threeDSecure';
import { useShowPayPal, useShowApplePay } from 'core/hooks/use-show-payment-method';
import { Purchase } from 'core/interfaces/billing';

import { processEmail } from 'src/store/profile/actions';

import ErrorPopup from './components/error-popup';

export interface PaymentProcessorRef {
  showPaymentPopup: () => void;
  closePaymentPopup: () => void;
  processFormData: () => void;
}

interface PaymentProcessorProps {
  activeProduct: ProductDetails,
  fullMode?: boolean,
  shownByDefault?: boolean,
  orderDetails?: any,
  onSuccess: (purchase?: any) => void,
  onError?: (error: any) => void,
  onClose?: () => void,
}

export const PaymentProcessor = React.forwardRef<PaymentProcessorRef, PaymentProcessorProps>(({
  activeProduct,
  fullMode,
  shownByDefault,
  orderDetails,
  onSuccess,
  onClose,
  onError,
}, ref) => {
  const dispatch = useDispatch();

  const [showPaymentPopup, togglePaymentPopup] = useState(!!shownByDefault);
  const [paymentData, setPaymentData] = useState(null);
  const [tokenThreeDSecure, setTokenThreeDSecure] = useState(null);

  const paddleConfig = useSelector(selectPaddleConfig);
  const postcode = useSelector(selectPostcode);
  const isLoadingBilling = useSelector(selectLoading);
  const purchased = useSelector(selectPurchased);
  const isLoadingApp = useSelector((state: any) => !state.app.loaded);

  const email = useSelector((state) => state.profile.email);

  const [isOpenErrorPopup, setIsOpenErrorPopup] = useState(false);

  const { modeGooglePay, cardFormLayout, errorPopup, defaultInjectedPaymentMethod } = useRemoteConfig();

  const paymentSystem = useSelector(selectPaymentSystem);

  const showPayPal = useShowPayPal();
  const showApplePay = useShowApplePay();

  const showGooglePay = modeGooglePay !== ModeGooglePay.DISABLED;

  const options = useMemo(() => ({
    orderDetails,
    config: Object.assign({ email, postcode }, paddleConfig),
    layout: cardFormLayout,
    applePayButtonParams: {
      color: 'black',
      enabled: showApplePay,
      disabled: !email && paymentSystem !== PaymentSystem.SOLIDGATE && paymentSystem !== PaymentSystem.PADDLE,
      containerId: 'container-solidgate-apple-pay',
    },
    googlePayButtonParams: {
      color: 'black',
      type: 'plain',
      mode: modeGooglePay,
      disabled: !email && paymentSystem !== PaymentSystem.SOLIDGATE && paymentSystem !== PaymentSystem.PADDLE,
      containerId: 'container-solidgate-google-pay',
    },
    formParams: {
      autoFocus: false,
    },
  }), [orderDetails, email, postcode, paddleConfig, cardFormLayout, showApplePay, paymentSystem, modeGooglePay]);

  const handleClosePaymentPopup = () => {
    togglePaymentPopup(false);
    onClose && onClose();
    dispatch(setInjectedPaymentMethod(defaultInjectedPaymentMethod));
  };

  const handlePaymentSuccess = useCallback((purchase) => {
    Analytics.trackEvent('modal_payment', 'success');
    onSuccess(purchase);
  }, [onSuccess]);

  const handleCardPaymentSuccess = async (purchase: Purchase) => {
    if (purchase.paymentSystem === PaymentSystem.PADDLE) {
      const em = purchase.email || email;
      dispatch(processEmail(em));
      // we do not handle purchase here because purchase is handled at success-url
    } else {
      if (!activeProduct?.isOneTimePurchase) {
        dispatch(
          handleSuccessPurchase({
            ...purchase,
            price_id: activeProduct?.id,
          }),
        );
      }
      togglePaymentPopup(false);
      setTimeout(() => {
        handlePaymentSuccess(purchase);
      }, 1);
    }
  };

  const handleCardPaymentError = useCallback((error) => {
    Analytics.trackEvent('modal_payment', 'error', { message: error?.message, paymentSystem: error?.paymentSystem });

    const insufficientFundsErrorCode = '3.02';
    let noToast;

    if (!activeProduct?.isOneTimePurchase && errorPopup?.enabled && error?.code !== insufficientFundsErrorCode) {
      Analytics.trackEvent('error_popup', 'open', { message: error?.message, paymentSystem: error?.paymentSystem });
      togglePaymentPopup(false);
      setIsOpenErrorPopup(true);
      dispatch(switchToReservePaymentSystem());
      noToast = true;
    }

    dispatch(handleErrorPurchase(error, { noToast }));

    if (error?.paymentSystem === PaymentSystem.SOLIDGATE) {
      dispatch(initOrders());
    }

    onError && onError(error);
  }, [activeProduct?.isOneTimePurchase, dispatch, errorPopup?.enabled, onError]);

  const onActionTokenExchanged = useCallback((actionToken) => {
    setTokenThreeDSecure(null);

    dispatch(subscribe({ ...paymentData, three_d_secure_action_result_token_id: actionToken.id }))
      .then(() => {
        setPaymentData(null);
        handlePaymentSuccess();
      })
      .catch((error) => {
        toast(error?.message || '3D Secure failed');
        Analytics.trackEvent('ecommerce', 'error', { message: error?.message || '3D Secure failed', code: error?.code });
      });
  }, [dispatch, handlePaymentSuccess, paymentData]);

  const onActionTokenError = useCallback((error) => {
    setTokenThreeDSecure(null);
    handleCardPaymentError(error);
  }, [handleCardPaymentError]);


  const processFormData = async (formData) => {
    Analytics.trackEvent('modal_payment', 'submit', { method: formData?.method, paymentSystem: formData?.paymentSystem });
    setPaymentData(formData);
    dispatch(subscribe(formData))
      .then(() => {
        handlePaymentSuccess();
      })
      .catch((error) => {
        if (error?.data?.three_d_secure_action_token_id) {
          setTokenThreeDSecure(error?.data?.three_d_secure_action_token_id);
        } else {
          handleCardPaymentError(error);
        }
      });
  };

  const handleSubmit = async (formData) => {
    const ps = formData.paymentSystem || paymentSystem;
    if (formData.email && formData.email !== email) {
      dispatch(processEmail(formData.email));
    }
    await dispatch(createCustomer({ email: formData.email, paymentSystem: ps }));
    if (formData.email || formData.first_name || formData.last_name) {
      Analytics.trackEvent('user', 'info', {
        email: formData.email || undefined,
        first_name: formData.first_name || undefined,
        last_name: formData.last_name || undefined,
      });
    }
    if (ps === PaymentSystem.SOLIDGATE) {
      Analytics.trackEvent('modal_payment', 'submit', { method: formData?.method, paymentSystem: formData?.paymentSystem });
    } else {
      await processFormData(formData);
    }
  };

  useImperativeHandle(ref, () => {
    return {
      showPaymentPopup: () => togglePaymentPopup(true),
      closePaymentPopup() {
        handleClosePaymentPopup();
      },
      processFormData: processFormData,
    }
  });

  const handleApplePayClick = () => {
    Analytics.trackEvent('apple_pay', 'click');
  };

  const handleApplePaySubmit = (formData) => {
    Analytics.trackEvent('apple_pay', 'submit');
    return handleSubmit(formData);
  };

  const handleGooglePayClick = () => {
    Analytics.trackEvent('google_pay', 'click');
  };

  const handleGooglePaySubmit = (formData) => {
    Analytics.trackEvent('google_pay', 'submit');
    return handleSubmit(formData);
  };

  const handlePayPalSubmit = (formData) => {
    return processFormData(formData);
  };

  const handleUserInfoChange = (info) => {
    const em = info?.email;
    if (em && em !== email) {
      dispatch(processEmail(em));
      Analytics.setUserProperty('email', em);
      Analytics.trackEvent('user', 'info', { em, });
      dispatch(initOrders());
    }
    if (info?.zip) {
      dispatch(setPostCode(info.zip));
    }
  }

  const handleCardFormSubmit = (formData) => {
    dispatch(setIsSubmitByCard(true));
    Analytics.trackEvent('card', 'submit');
    return handleSubmit(formData);
  };

  const handleErrorPopupCancel = () => {
    setIsOpenErrorPopup(false);
    Analytics.trackEvent('error_popup', 'cancel');
    onClose && onClose();
  }

  const handleErrorPopupSubmit = () => {
    setIsOpenErrorPopup(false);
    Analytics.trackEvent('error_popup', 'submit');
    togglePaymentPopup(true);
  }

  return (
    <>
      {(isLoadingApp || isLoadingBilling) ?
        <Preloader /> :
        <Provider>
          <BasePaymentModal
            activeProduct={activeProduct}
            showPopup={showPaymentPopup}
            onClose={handleClosePaymentPopup}
            onSuccess={handleCardPaymentSuccess}
            onError={handleCardPaymentError}
            options={options}
            showApplePay={showApplePay}
            showGooglePay={showGooglePay}
            showPayPal={showPayPal}
            onApplePayClick={handleApplePayClick}
            onApplePaySubmit={handleApplePaySubmit}
            onGooglePayClick={handleGooglePayClick}
            onGooglePaySubmit={handleGooglePaySubmit}
            onPayPalSubmit={handlePayPalSubmit}
            onUserInfoChange={handleUserInfoChange}
            onSubmit={handleCardFormSubmit}
            fullMode={fullMode}
          />
        </Provider>}


      {tokenThreeDSecure && (
        <ThreeDSecure
          actionTokenId={tokenThreeDSecure}
          onToken={onActionTokenExchanged}
          onError={onActionTokenError}
        />
      )}

      {errorPopup?.enabled && (
        <ErrorPopup
          onClose={handleErrorPopupCancel}
          visible={isOpenErrorPopup}
          onSubmit={handleErrorPopupSubmit}
          buttonTitle={errorPopup.buttonTitle}
          type={errorPopup.type}
        />
      )}
    </>
  );
});
