import React, { useEffect, useId, useRef } from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide, SwiperProps } from 'swiper/react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Button } from 'core/ui-elements';

import 'swiper/css';
import 'swiper/css/pagination';

import './style.scss';
import classes from './style.module.scss';

SwiperCore.use([Pagination]);

interface Props {
  slides: React.ReactNode[];
  className?: string;
  onClick?: () => void;
  onClickButton?: () => void;
  isButton?: boolean;
  onSwipe?: (x: number) => void;
}

const SwiperSlider: React.FC<Props & SwiperProps> = ({
  slides,
  className,
  onClick,
  onClickButton,
  isButton = false,
  onSwipe,
  ...other
}) => {

  const wrapStyle = classNames([
    classes.wrap,
    className,
    { 'wrap-with-button': isButton },
  ]);
  const swiperRef = useRef<SwiperRef>(null);
  const swiperId = useId();

  useEffect(() => {
    const element = document.getElementById(swiperId);

    element?.addEventListener("touchmove", (e) => {
      e.preventDefault();
    });
  });

  const handleBtnClick = () => {
    const swiper = swiperRef.current?.swiper;

    const isLastSlide = swiper?.isEnd;

    if (isLastSlide && onClickButton) {
      onClickButton();
    } else {
      swiper?.slideNext();
    }
  };

  const renderSlides = () =>
    slides.map((slide, i) => (
      <SwiperSlide key={`slide-${i}`} onClick={onClick}>
        {slide}
      </SwiperSlide>
    ));

  const handleSwipe = () => {
    const swiper = swiperRef.current?.swiper;

    if (swiper && onSwipe) {
      onSwipe(swiper?.activeIndex);
    }
  }

  return (
    <div className={wrapStyle} id={swiperId}>
      <Swiper
        centeredSlides
        ref={swiperRef}
        pagination
        onActiveIndexChange={handleSwipe}
        {...other}
      >
        {renderSlides()}
      </Swiper>
      {isButton && (
        <Button
          flare
          title={t('_continue')}
          onClick={handleBtnClick}
          iconRight={false}
          className={classes.button}
        />
      )}
    </div>
  );
};

export default SwiperSlider;
