import _findIndex from 'lodash/findIndex';
import _omit from 'lodash/omit';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { createAction } from 'redux-actions';

import Analytics from '@web-solutions/module-analytics';
import { PaymentSystem } from '@web-solutions/react-billing';

import { stringifyUrlParams } from 'core/utils/url-sync';
import { setPurchase } from 'core/store/billing/actions';
import { remoteConfigSelector } from 'core/store/remote-config/selectors';
import { REDIRECT_TO_CHROME, redirectToChrome } from 'core/utils/redirect-android';

import { SUBSCRIPTION_NAMES } from 'src/constants/screens';
import { ROUTES, SUBSCRIPTION, SCREENS_WITH_AUTH } from 'src/constants/routes';

import { preloadPage } from 'src/utils/route';

import { processEmail, authAndSetUserData, } from '../profile/actions';

import { SET_COMPLETED, SET_START_ROUTE, SET_STEP } from './types';

import { selectFlow } from './selectors';

export const setStep = createAction(SET_STEP);
export const setStartRoute = createAction(SET_START_ROUTE);
export const setCompleted = createAction(SET_COMPLETED);

export const init = ({ linkAuthResult } = {}) => (dispatch, getState) => {
  const { redirectToChrome: rtc } = remoteConfigSelector(getState());
  const { billing: { purchase } } = getState();

  if (rtc === REDIRECT_TO_CHROME.ENABLED) {
    Analytics.trackEvent('android', 'redirect', { mode: rtc });
    redirectToChrome();
  }

  const params = queryString.parse(window.location.search);

  if (params.completed !== undefined) {
    dispatch(setCompleted(true));
  }

  if (params.purchased !== undefined && !purchase) {
    dispatch(setPurchase({}));
  }

  if (params.email) {
    dispatch(processEmail(params.email));
  }

  const startRoute = dispatch(getStartRoute({ linkAuthResult }));
  dispatch(setStartRoute(startRoute));

  return Promise.resolve();
};

const getRoute = () => (_, getState) => {
  const {
    routing: { currentStep },
  } = getState();

  const flow = selectFlow(getState());

  preloadPage(flow[currentStep + 1]);
  preloadPage(flow[currentStep + 2]);

  return ROUTES[flow[currentStep].toUpperCase()];
};

export const getRouteAfterFlow = () => (dispatch, getState) => {
  const {
    remoteConfig: { presummaryStack },
    billing: { purchased },
  } = getState();
  if (purchased) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS];
  } else if (presummaryStack?.length) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.PRESUMMARY];
  } else {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.MAIN];
  }
};

export const setPrevStep = () => (dispatch, getState) => {
  const {
    routing: { currentStep },
  } = getState();

  const step = currentStep > 0 ? currentStep - 1 : 0;

  dispatch(setStep(step));
};

export const nextScreen = (history) => async (dispatch, getState) => {
  const {
    routing: { currentStep, isCompleted },
  } = getState();

  const { redirectToChrome: rtc } = remoteConfigSelector(getState());

  if (currentStep === 0 && rtc === REDIRECT_TO_CHROME.ON_CLICK) {
    Analytics.trackEvent('android', 'redirect', { mode: rtc });
    redirectToChrome();
  }

  const flow = selectFlow(getState());

  const step = currentStep + 1;

  if (step === flow.length || isCompleted) {
    if (!isCompleted) {
      dispatch(setCompleted(true));
      dispatch(authAndSetUserData());
      stringifyUrlParams({ completed: null })
    }

    history.push(dispatch(getRouteAfterFlow()));
  }
  else {
    dispatch(setStep(step));
    history.push(dispatch(getRoute()));
  }
};

export const prevScreen = (history) => (dispatch, getState) => {
  const {
    routing: { currentStep },
  } = getState();

  const step = currentStep > 0 ? currentStep - 1 : 0;

  dispatch(setStep(step));

  history.push(dispatch(getRoute()));
};

export const getStartRoute = ({ linkAuthResult } = {}) => (dispatch, getState) => {
  const {
    routing: { isCompleted },
  } = getState();

  const flow = selectFlow(getState());

  if (isCompleted) {
    return dispatch(getRouteAfterFlow());
  }

  const params = queryString.parse(window.location.search);
  if (linkAuthResult || params.auth === null) {
    createBrowserHistory()
      .replace(queryString.stringifyUrl({
        url: window.location.pathname,
        query: _omit(params, ['auth']),
      }));
    const index = _findIndex(flow, r => SCREENS_WITH_AUTH.includes(r));
    dispatch(setStep(index));
  }

  return dispatch(getRoute());
};

export const getRouteAfterPurchased = (history) => (dispatch, getState) => {
  const {
    remoteConfig: { afterPurchaseFlow },
    billing: { purchase },
  } = getState();
  const paymentSystem = purchase?.paymentSystem;
  if (paymentSystem !== PaymentSystem.SOLIDGATE) {
    return SUBSCRIPTION[SUBSCRIPTION_NAMES.SUCCESS];
  }
  const isTrial = purchase?.isTrial && purchase?.product?.trialPeriodPriceValue === 1;
  const flow = [SUBSCRIPTION_NAMES.MAIN, ...afterPurchaseFlow];
  const currentStep = history.location.pathname;
  let nextStepIndex = flow.findIndex((route) => currentStep === SUBSCRIPTION[route]) + 1
  if (!isTrial && flow[nextStepIndex] === SUBSCRIPTION_NAMES.TRIAL_PLAN_CHANGE) {
    nextStepIndex += 1
  }
  const nextStep = flow[nextStepIndex] ? flow[nextStepIndex] : SUBSCRIPTION_NAMES.SUCCESS;

  return SUBSCRIPTION[nextStep];
};
