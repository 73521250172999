import React from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { countries } from '../../data';

import classes from '../../style.module.scss';

const Country = ({ onChange, id, value, isValid, placeholder }) => (
  <>
    <select
      id={id}
      value={value || ''}
      onChange={({ target }) => onChange(target.value, target.name)}
      name={id}
      className={classNames(classes.select, classes.input, { [classes.placeholder]: value === '' }, { [classes.invalid]: !isValid })}
    >
      <option value={''} disabled hidden>{t(placeholder)}</option>
      {Object.values(countries).map((item) =>
        <option key={item} value={item}>{item}</option>
      )}
    </select>
    <div className={classes.select__arrow} />
  </>
);

export default Country;
