import React, { forwardRef, useState, useImperativeHandle, useEffect, useCallback } from 'react';
import classNames from 'classnames';

import Mutator from '@magnus/react-native-mutator';

import { t } from '@web-solutions/module-localization';

import { NAMES, countries, data, initialStateValues, initialStateValidation } from './data';
import Input from './components/input';
import Country from './components/country';
import Phone from './components/phone';
import FullName from './components/full-name';

import classes from './style.module.scss';

const tKey = 'core.shipping_address';

const ShippingAddress = forwardRef(({ onChange }, ref) => {
  const [fieldsValue, setFieldsValue] = useState(initialStateValues);
  const [fieldsValidation, setFieldsValidation] = useState(initialStateValidation);

  useImperativeHandle(ref, () => ({
    validateFields() {
      let values = {};
      let isValidFields = true;

      for (const name in fieldsValue) {
        const value = fieldsValue[name];

        if (!value?.length > 0) {
          isValidFields = false;
        }
        values[name] = value?.length > 0;
      }
      setFieldsValidation(values);

      return isValidFields;
    }
  }));

  const handleChange = useCallback((value, name) => {
    setFieldsValue({ ...fieldsValue, [name]: value });
    setFieldsValidation({ ...fieldsValidation, [name]: value.length > 0 });
    onChange({ ...fieldsValue, [name]: value });
  }, [fieldsValidation, fieldsValue, onChange]);

  useEffect(() => {
    Mutator.getCountryCode()
      .then(data => {
        handleChange(countries[data] || '', NAMES.COUNTRY)
      })
      .catch((err) => {
        console.warn(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContent = (id, type, placeholder) => {
    switch (id) {
      case NAMES.COUNTRY:
        return (
          (
            <Country
              id={id}
              onChange={handleChange}
              value={fieldsValue[id]}
              isValid={fieldsValidation[id]}
              placeholder={placeholder}
            />
          )
        );
      case NAMES.PHONE_NUMBER:
        return (
          <Phone
            id={id}
            onChange={handleChange}
            value={fieldsValue[id]}
            isValid={fieldsValidation[id]}
            countryCode={fieldsValue[NAMES.COUNTRY]}
          />
        );
      case NAMES.FULL_NAME:
        return (
          <FullName
            id={id}
            onChange={handleChange}
            value={fieldsValue[id]}
            isValid={fieldsValidation[id]}
            placeholder={placeholder}
          />
        );
      default:
        return (
          <Input
            id={id}
            onChange={handleChange}
            value={fieldsValue[id]}
            isValid={fieldsValidation[id]}
            type={type}
            placeholder={placeholder}
          />
        )
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t(`${tKey}.title`)}</div>
      {
        data.map(({ id, type, placeholder }) => (
          <div key={id} className={classNames(classes.item, classes[id])}>
            <label form={id} className={classes.label}>{t(`${tKey}.fields.${id}`)}</label>
            {getContent(id, type, placeholder)}
          </div>
        ))
      }
    </div>
  )
});

export default ShippingAddress;
